import Cookies from 'js-cookie';
import React from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import SideBar from '../components/SideBar';
import "./styles/dashboard.css"

const Page404 = () => {

    
    if(!Cookies.get("mhealth_devices_access_token")){
        return (<Navigate to="/login" />)
    }
    return (
        <>
            <NavBar/>
            <SideBar/>
            
            <div className="page-wrapper">

            <div className="page-content">

                    <div>
                        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                                <div className="ps-3">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 p-0">
                                            <li className="breadcrumb-item"><NavLink to="/"><i className="bx bx-home-alt"></i></NavLink></li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                    </div>

            
                    <div className="error-404 d-flex justify-content-center mt-4">
                        <div className="container">
                            <div className="card py-5">
                                <div className="row g-0">
                                    <div className="col col-xl-5">
                                        <div className="card-body p-4">
                                            <h1 className="display-1 text-warning"><span className="">4</span><span className="">0</span><span className="">4</span></h1>
                                            <h2 className="font-weight-bold display-4 text-warning">Ressources indisponible !</h2>
                                            <p className="text-light">La réssource que vous tenter d'acceder n'est pas disponible.
                                                <br/>Il se pourrait aussi que vous n'avez pas les privilèges nécessaires.</p>
                                            <div className="mt-5"> 
                                                <a href="/" className="btn btn-light btn-lg px-md-5 radius-30">Dashboard</a>
                                                {/* <a href="javascript:;" className="btn btn-light btn-lg ms-3 px-md-5 radius-30">Back</a> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-7  text-center p-0 mr-3">
                                        <img style={{height:"300px", width:"650px"}} src="https://cdn.searchenginejournal.com/wp-content/uploads/2019/03/shutterstock_1338315902.png" className="img-fluid" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer className="fixed-bottom"  />
        </>
    );
};

export default Page404;