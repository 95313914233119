import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import { Navigate, NavLink, useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import SideBar from '../../components/SideBar';
import useGet from '../../hooks/useGet';
import { useState } from 'react';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import AddIcon from '@material-ui/icons/Add';
import "../styles/tablesStyles.css"
import Cookies from 'js-cookie';
import { IconButton } from '@material-ui/core';
import axios from 'axios';
import "../styles/tablesStyles.css"
import 'react-notifications/lib/notifications.css';
import { io } from "socket.io-client";



const UserList = ({change_detect_}) => {

    const navigate = useNavigate()

    const [change_detect, setchange_detect] = useState(Math.floor(Math.random() * 10000000) + 1)

    const  { datas:usersDatas, count, error, loading } = useGet("api/auth/users/", change_detect)  

    

    const columns = [

        { title: "Nom d'utilisateur", field: "username",filtering: false, validate: rowData => rowData.username === '' ? 'Ce champ ne peut etre vide !' : '', filterPlaceholder: "filter", emptyValue: () => <em>null</em> },
        
        { title: "Mot de passe", field: "password",filtering: false, validate: rowData => rowData.password === '' ? 'Ce champ ne peut etre vide !' : '', filterPlaceholder: "filter", align:"center", render: () => <em>-</em> },

        { title: "Type ?", field: "is_admin", type:"boolean", validate: rowData => rowData.is_admin === '' ? 'Ce champ ne peut etre vide !' : '', filtering: true  , align:"center", lookup: { true: "Super admin", false: "Admin" },
            render : (rowData)=>(
                rowData.is_admin ? <span className="badge text-bg-info">Super admin</span> : <span className="badge text-bg-warning">Admin</span> 
            )
        }, 
              

        // {
        //     title : "", align : "right",
        //     render : (rowData)=>(
        //         <>                    
        //             <IconButton  title="Afficher les details de cet utilisateur ?" style={{color : "#fff"}} aria-label="add an alarm">
        //                 <RemoveRedEye onClick={(e)=>{ e.preventDefault(); }}  />
        //             </IconButton>
        //         </>
        //     )
        // }
    ]

                               
    
    //Verifier les privileges superadmin
    const IsAdmin = ()=>{
        if(Cookies.get('mhealth_devices_user_infos')){
            const user_infos_is_admin = (JSON.parse(Cookies.get('mhealth_devices_user_infos'))).is_admin
            if(user_infos_is_admin===true){
                return true
            }
            else{return false}
        } else{return false}
    }


    useEffect(() => {

        const socket = io("https://mdm.mhealth.interne.lagfo:444");

        // listen for chat events
        socket.on("onUserDataChange", (data) => {
            setchange_detect(Math.floor(Math.random() * 10000000) + 1)
        })

        // when component unmounts, disconnect
        return (() => {
            socket.disconnect()
        })
    }, [])



    
    if(!Cookies.get("mhealth_devices_access_token")){
        return (<Navigate to="/login" />)
    }

    return (
            <>
                <NavBar change_detect={change_detect_} />
                <SideBar/>
                
                <div className="page-wrapper">

                <div className="page-content">

                        <div>
                            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                                <div className="breadcrumb-title pe-3 text-light h4">Utilisateurs</div>
                                    <div className="ps-3">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb mb-0 p-0">
                                                <li className="breadcrumb-item"><NavLink className="text-light" to="/"><i className="bx bx-home-alt"></i></NavLink></li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                        </div>

                        <div>
                        
                            <div className="mb-3 card">
                                <MaterialTable
                                    title="Utilisateurs"
                                    columns={columns} 
                                    data={usersDatas}
                                
                                    isLoading={loading}
                                    
                                    editable={{
                                        onRowAdd: IsAdmin() ? (newRow) => new Promise((resolve, reject) => {
                                        axios
                                            .post( `/api/auth/register/` , {
                                                "username" : newRow.username,
                                                "password" : newRow.password,
                                                "is_admin" : newRow.is_admin === "true"
                                            })
                                            .then((res) => {
                                                setchange_detect(Math.floor(Math.random() * 10000000) + 1)
                                                resolve()                                         
                                            })
                                            .catch((err) => {
                                                console.log(err)
                                                if(err.response.data.code==401){
                                                    console.log("unhautorized")
                                                    navigate("/unauthorized")
                                                }
                                            })
                                    }) : "" ,


                                    onRowUpdate: IsAdmin() ?  (newRow, oldRow) => new Promise((resolve, reject) => {
                                        axios
                                            .put( `/api/auth/user/${oldRow.username}/` , {
                                                "username" : newRow.username,
                                                "password" : newRow.password,
                                                "is_admin" : newRow.is_admin === "true"
                                            })
                                            .then((res) => {
                                                setchange_detect(Math.floor(Math.random() * 10000000) + 1)
                                                resolve()                                         
                                            })
                                            .catch((err) => {
                                                console.log(err)
                                                if(err.response.data.code==401){
                                                    console.log("unhautorized")
                                                    navigate("/unauthorized")
                                                }
                                            })
                                    }):"",


                                    onRowDelete: IsAdmin() ?  (selectedRow) => new Promise((resolve, reject) => {
                                        axios
                                            .delete( `/api/auth/user/${selectedRow.username}/`)
                                            .then((res) => {
                                                setchange_detect(Math.floor(Math.random() * 10000000) + 1)
                                                resolve()                                         
                                            })
                                            .catch((err) => {
                                                console.log(err)
                                                if(err.response.data.code==401){
                                                    console.log("unhautorized")
                                                    navigate("/unauthorized")
                                                }
                                            })
                                    }):""
                                }}
                                    
                                    
                                    onSelectionChange={(selectedRows) => console.log(selectedRows)}
                                    options={{
                                        sorting: true, search: true,
                                        searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
                                        filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
                                        paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
                                        exportAllData: true, exportFileName: "mHealthDeviceDatas", addRowPosition: "first", actionsColumnIndex: -1, selection: false,
                                        showSelectAllCheckbox: false, showTextRowsSelected: false,
                                        grouping: false, columnsButton: true,
                                    }}

                                    icons={{ Add: () => <AddIcon /> }}


                                    localization={{
                                        body: {
                                            emptyDataSourceMessage: "Pas d'utilisateur à afficher",
                                            addTooltip: 'Ajouter',
                                            deleteTooltip: 'Supprimer',
                                            editTooltip: 'Editer',
                                            filterRow: {
                                                filterTooltip: 'Filtrer'
                                            },
                                            editRow: {
                                                deleteText: 'Voulez-vous supprimer cette ligne?',
                                                cancelTooltip: 'Annuler',
                                                saveTooltip: 'Enregistrer'
                                            }
                                        },
                                        grouping: {
                                            placeholder: "Tirer l'entête ...",
                                            groupedBy: 'Grouper par:'
                                        },
                                        header: {
                                            actions: 'Actions'
                                        },
                                        pagination: {
                                            labelDisplayedRows: '{from}-{to} de {count}',
                                            labelRowsSelect: 'lignes',
                                            labelRowsPerPage: 'lignes par page:',
                                            firstAriaLabel: 'Première page',
                                            firstTooltip: 'Première page',
                                            previousAriaLabel: 'Page précédente',
                                            previousTooltip: 'Page précédente',
                                            nextAriaLabel: 'Page suivante',
                                            nextTooltip: 'Page suivante',
                                            lastAriaLabel: 'Dernière page',
                                            lastTooltip: 'Dernière page'
                                        },
                                        toolbar: {
                                            addRemoveColumns: 'Ajouter ou supprimer des colonnes',
                                            nRowsSelected: '{0} ligne(s) sélectionée(s)',
                                            showColumnsTitle: 'Voir les colonnes',
                                            showColumnsAriaLabel: 'Voir les colonnes',
                                            exportTitle: 'Exporter',
                                            exportAriaLabel: 'Exporter',
                                            searchTooltip: 'Chercher',
                                            searchPlaceholder: 'Chercher',
                                            exportPDFName: "Exporter en PDF",
                                            exportCSVName : "Exporter en CSV"
                                        }
                                    }}
                                />
                            </div>


                        </div>
                        
                    </div>
                </div>
                <Footer className="fixed-bottom"  />
            </>
    );
};

export default UserList;