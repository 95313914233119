import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import useGet from '../hooks/useGet';

const NavBar = ({change_detect}) => {

    const navigate = useNavigate()    
    const [username, setUsername] = useState("Admin")
    const [is_admin, setisAdmin] = useState(true)
    const [mhealth_devices_user_infos, setmhealth_devices_user_infos] = useState(Cookies.get("mhealth_devices_user_infos"))
    const [notificationsData, setnotificationsData] = useState([])
    const [notificationsNumber, setnotificationsNumber] = useState(0)
    const [change_detect_, setchange_detect_] = useState(Math.floor(Math.random() * 10000000) + 1)

    useEffect(() => {
        setTimeout(()=>{
            setUsername(JSON.parse(mhealth_devices_user_infos)?.username)
            setisAdmin(JSON.parse(mhealth_devices_user_infos)?.is_admin)
        }, 300)
    }, [])

    useEffect(() => {
      
        axios.get("api/lost/device/notification")
            .then((res)=>{
                setnotificationsData(res?.data?.data)
                console.log(res?.data?.data)
                setnotificationsNumber(Object.keys(res?.data?.data).length)
            })

    }, [change_detect, change_detect_])
    


    
    
    return (
        <div>
            <header>
                <div className="topbar d-flex align-items-center">
                    <nav className="navbar navbar-expand">
                        <div className="mobile-toggle-menu"><i className='bx bx-menu'></i>
                        </div>


                        <div className="top-menu ms-auto me-auto text-center" style={{ textAlign: "center" }} >
                            <select className='form-select text-light' onChange={(e)=>{
                                    e.preventDefault();
                                    if(e.target.value=="mHealthBurkina"){
                                        document.location = "https://mdm.mhealth.bf/"
                                    }
                                }} >
                                <option value="mHealthBurkina">mHealth Burkina</option>
                                <option selected value="mHealthNiger" >mHealth Niger</option>
                            </select>
                        </div>


                        <ul type="none" className='me-3'>
                            <li className="nav-item dropdown dropdown-large mt-3" >
                                    <a className="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                        <span className="alert-count"> {notificationsNumber} </span>
                                        <i className='bx bx-bell' style={{fontSize: "30px" }}></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                        <a href="#">
                                            <div className="msg-header">
                                                <p className="msg-header-title">Notifications</p>
                                                <a href='#' onClick={(e)=>{
                                                    e.preventDefault()
                                                }} className="msg-header-clear ms-auto">Tout marquer comme lus</a>
                                            </div>
                                        </a>
                                        <div className="header-notifications-list" >
                                            {
                                                notificationsNumber > 0 ?
                                                notificationsData?.map(data =>{
                                                        const url = "/#/device/" + data.device_id + "/location"
                                                        return(
                                                            <a className="dropdown-item" key={data.id} href={url}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="notify"><iconify-icon icon="material-symbols:device-unknown-outline-rounded"></iconify-icon>
                                                                    </div>
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="msg-name">Alerte téléphone ! </h6>
                                                                        <span className="msg-info"> { data?.content } </span>
                                                                    </div>
                                                                    <button onClick={(e)=>{
                                                                        e.preventDefault()
                                                                        axios.delete( `api/lost/device/notification/${data.id}`)
                                                                            .then((res) => {
                                                                                setchange_detect_(Math.floor(Math.random() * 10000000) + 1)
                                                                            })
                                                                    }} className='btn btn danger text-white'><iconify-icon icon="material-symbols:delete-outline"></iconify-icon></button>
                                                                </div>
                                                            </a>
                                                        );
                                                    }) 
                                                    : <h6 className='text-center mt-3'>Aucune notification disponible</h6>
                                            }                                           
                                           										
                                        </div>
                                    </div>
                                </li>
                            </ul>









                        <div className="user-box dropdown">
                            <a className="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtL1hQwbxcHmbrrClx2Ro1yfPqMT0I3TYvVW9NtX2e8XH79nUhCDv7kodNtC7hw2EBsXU&usqp=CAU" className="user-img" alt="user avatar"/>
                                <div className="user-info ps-3">
                                    <p className="user-name mb-0">{username}</p>
                                    <p className="designattion mb-0">{is_admin ? "SuperAdmin" : "Admin"}</p>
                                </div>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end">
                                {/* <li><a className="dropdown-item" href="#"><i className="bx bx-user"></i><span>Profile</span></a></li> */}
                                <li><a className="dropdown-item" href="#"><i className='bx bx-home-circle'></i><span>Dashboard</span></a>
                                </li>
                                <li>
                                    <div className="dropdown-divider mb-0"></div>
                                </li>
                                <li><a className="dropdown-item" href="#" 
                                            onClick={(e)=>{
                                                e.preventDefault()
                                                Swal.fire({
                                                title: 'Etes-vous sur(e) ?',
                                                text: "Vous etes entrain de vous déconnecter !",
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonColor: '#3085d6',
                                                cancelButtonColor: '#d33',
                                                confirmButtonText: 'Oui !',
                                                cancelButtonText : "Annuller"
                                                }).then((result) => { 
                                                    if (result.isConfirmed) {                                           
                                                        Cookies.remove("mhealth_devices_access_token")      
                                                        Cookies.remove("mhealth_devices_user_infos") 
                                                        Cookies.remove("devices_count")     
                                                        navigate("/login")   
                                                    }                                          
                                                })
                                            }
                                            }
                                ><i className='bx bx-log-out-circle' style={{color : 'black !important'}}></i><span>Se déconnecter</span></a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        </div>
    );
};

export default NavBar;