import React, { useEffect } from 'react';
import NavBar from '../../components/NavBar';
import SideBar from '../../components/SideBar';
import MaterialTable from 'material-table';
import { useState } from 'react';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import List from '@material-ui/icons/List';
import AddIcon from '@material-ui/icons/Add';
import ToggleOn from '@material-ui/icons/ToggleOn';
import ToggleOff from '@material-ui/icons/ToggleOff';
import "../styles/tablesStyles.css"
import BreadCrum from '../../components/BreadCrum';
import Footer from '../../components/Footer';
import { IconButton } from '@material-ui/core';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Navigate, NavLink, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import "../styles/tablesStyles.css"
import DatePicker from 'react-date-picker';
import dateFormat from "dateformat"
import { Icon } from '@iconify/react';
import PhonelinkEraseIcon from '@material-ui/icons/PhonelinkErase';
 

const DevicesList = ({change_detect_}) => {

    const navigate = useNavigate()

    const [selectedYear, setselectedYear] = useState("")
    const [selectedMonth, setselectedMonth] = useState("")
    const [selectedDay, setselectedDay] = useState("")

    const [change_detect, setchange_detect] = useState(Math.floor(Math.random() * 10000000) + 1)

    const [devicesDatas, setdevicesDatas] = useState([])

    const [loading, setloading] = useState(true)
    useEffect(() => {
        axios.get("/api/device/")
            .then((response) =>{
                setloading(false)
                setdevicesDatas(response?.data?.data)
            })
    }, [change_detect])
    
    const [filteredDeviceData, setfilteredDeviceData] = useState([...devicesDatas])
                                    
    
    //Verifier les privileges superadmin
    const IsAdmin = ()=>{
        if(Cookies.get('mhealth_devices_user_infos')){
            const user_infos_is_admin = (JSON.parse(Cookies.get('mhealth_devices_user_infos'))).is_admin
            if(user_infos_is_admin===true){
                return true
            }
            else{return false}
        } else{return false}
    }
    


    const columns = [

        { title: "ID", field: "device_id", sorting: true, filtering: false},

        { title: "Nom", field: "name",filtering: false, filterPlaceholder: "filter", emptyValue: () => <em>null</em> },

        { title: "Installé ?", field: "is_installed", filtering: true , lookup: { true: "Installé", false: "Non installé" },  align:"center",
            render : (rowData)=>(
                rowData.is_installed ? <span className="badge text-bg-success">Installé</span> : <span className="badge text-bg-danger">Non installé</span> 
            )
        }, 
        
        { title: "Activé ?", field: "is_activate", filtering: true, lookup: { true: "Activé", false: "Non activé" },  align:"center",
            render : (rowData)=>(
                rowData.is_activate ? <span className="badge text-bg-success">Activé</span> : <span className="badge text-bg-danger">Non activé</span> 
            )
        }, 
        
        { title: "Applications",filtering: true, align: "center", grouping: false , 
            render : (rowData)=>(
                
                    rowData.is_relais ?<span className="badge text-bg-info mr-1">RELAIS</span> : "" ||
                    rowData.is_csi ?<span className="badge text-bg-warning mx-1">CSI</span> : "" ||
                    !rowData.is_csi &&  !rowData.is_relais ?<span className="badge text-bg-danger mx-1">Aucune</span> : "" 
               
            )
        } ,       

        {
            title : "Actions",
            render : (rowData)=>(
                <>
                
                    {rowData.is_activate && IsAdmin() && rowData.is_installed && setdeviceloading=="" && (rowData.is_relais || rowData.is_csi) ? 
                    <IconButton title="Desactiver cet appareil ?" aria-label="add an alarm">
                        <ToggleOn  onClick={(e)=>{DesactivateDevice(rowData.device_id); e.preventDefault()}} />
                    </IconButton> : setdeviceloading}
                    {!rowData.is_activate && IsAdmin() && rowData.is_installed && setdeviceloading=="" && (rowData.is_relais || rowData.is_csi) ?
                    <IconButton style={{color : "red !important"}}  title="Activer cet appareil ?" aria-label="add an alarm">
                        <ToggleOff onClick={(e)=>{ActivateDevice(rowData.device_id); e.preventDefault()}}  style={{color : "red"}} />
                    </IconButton> : setdeviceloading}
                    
                    <IconButton  title="Afficher les details de cet appareil ?" style={{color : "#fff"}} aria-label="add an alarm">
                        <RemoveRedEye style={{color : "#fff"}} onClick={(e)=>{ e.preventDefault(); navigate("/device/" + rowData.device_id + "/details") }}  />
                    </IconButton>
                    
                    {/* <IconButton  title="Afficher l'historique de cet appareil ?" color="light" aria-label="add an alarm">
                        <List style={{color : "#fff"}} onClick={(e)=>{ e.preventDefault(); navigate("/device/" + rowData.device_id + "/historique") }}  />
                    </IconButton> */}
                </>
            )
        }
    ]

    const [setdeviceloading, set_setdeviceloading] = useState("")
    //testdevice
    const DesactivateDevice = (device_id)=>{
        Swal.fire({
            title: 'Etes-vous sur(e) ?',
            text: "Vous etes entrain de vous désactiver cet appareil !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui !',
            cancelButtonText : "Annuler"
            }).then((result) => { 
                if (result.isConfirmed) {                                      
                    set_setdeviceloading(<div className="spinner-border text-light" role="status">
                        <span className="sr-only"></span>
                    </div>)
                    axios
                    .put("/api/device/" + device_id + "/desactivate/")
                    .then((res) => {
                        set_setdeviceloading("")
                        setchange_detect(Math.floor(Math.random() * 10000000) + 1)
                    })  
                    .catch((err) => {
                        console.log(err)
                        if(err.response.data.code==401){
                            console.log("unhautorized")
                            navigate("/unauthorized")
                        }
                    })
                }                                          
            })
    } 
    
    const ActivateDevice = (device_id)=>{
        Swal.fire({
            title: 'Etes-vous sur(e) ?',
            text: "Vous etes entrain de vous d'activer cet appareil !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui !',
            cancelButtonText : "Annuler"
            }).then((result) => { 
                if (result.isConfirmed) {                                      
                    set_setdeviceloading(<div className="spinner-border text-light" role="status">
                        <span className="sr-only"></span>
                    </div>)
                    axios
                    .put("/api/device/" + device_id + "/activate/")
                    .then((res) => {
                            set_setdeviceloading("")
                            setchange_detect(Math.floor(Math.random() * 10000000) + 1)
                    }) 
                    .catch((err) => {
                        console.log(err)
                        if(err.response.data.code==401){
                            console.log("unhautorized")
                            navigate("/unauthorized")
                        }
                    }) 
                }                                          
            })
    } 

    useEffect(() => {
        setfilteredDeviceData([...devicesDatas])
    }, [devicesDatas])
    

    const filterDeviceByYear = (newYear)=>{
        setloading(true)
        if(!newYear) {            
            setselectedYear("")
            setfilteredDeviceData([...devicesDatas]); 
            setloading(false) 
        }
        else{
            const year = (newYear?.toString().split(' '))[3]
            console.log(year)
            const toFilt = devicesDatas?.filter(d=>(d?.date_creation)?.includes(year + "-"))
            setfilteredDeviceData(toFilt) 
        }    
        setloading(false) 
    }

    const filterDeviceByMonth = (newMonth)=>{
        setloading(true)
        if(!newMonth) {
            setselectedDay("")
            setselectedMonth("")
            const year = (selectedYear?.toString().split(' '))[3]
            const toFilt = devicesDatas?.filter(d=>(d?.date_creation)?.includes(year + "-"))
            setfilteredDeviceData([...toFilt])
            setloading(false) 
        }
        else{
            const month = dateFormat(newMonth, "mm")
            const toFilt = devicesDatas?.filter(d=>dateFormat(d?.date_creation, "mm")==month 
                            && dateFormat(d?.date_creation, "yyyy")==(selectedYear?.toString().split(' '))[3])
            setfilteredDeviceData(toFilt) 
        }    
        setloading(false) 
    }


    const filterDeviceByDay = (newDay)=>{
        setloading(true)
        if(!newDay) {
            setselectedDay("")
            const month = dateFormat(selectedMonth, "mm")
            const toFilt = devicesDatas?.filter(d=>dateFormat(d?.date_creation, "mm")==month 
                            && dateFormat(d?.date_creation, "yyyy")==(selectedMonth?.toString().split(' '))[3])
            setfilteredDeviceData(toFilt)
            setloading(false) 
        }
        else{
            const day = dateFormat(newDay, "dd")
            const toFilt = devicesDatas?.filter(d=>dateFormat(d?.date_creation, "mm")==dateFormat(selectedMonth, "mm")
                            && dateFormat(d?.date_creation, "yyyy")==dateFormat(selectedYear, "yyyy")
                            && dateFormat(d?.date_creation, "dd")==day )
            setfilteredDeviceData(toFilt) 
        }
        setloading(false)     
    }


    
    if(!Cookies.get("mhealth_devices_access_token")){
        return (<Navigate to="/login" />)
    }


    return (

        <div>
            <NavBar change_detect={change_detect_} />
            <SideBar/>
        
            <div className="page-wrapper">

                    <div className="page-content">

                            <div className='container'>
                            <div className="row" >
                                <div className='col-9'>
                                    <BreadCrum name1="Appareils" name2="Liste" />
                                </div>
                                <div className='col-3 text-end' >
                                    <NavLink to="/device-lost/list" className='btn btn-danger' style={{color: "white"}} >Téléphones perdus <PhonelinkEraseIcon/></NavLink>
                                </div>
                            </div>
                        </div>
                        
                        <div className="mb-3 card">

                            <div className="my-3 row mx-1">
                                <div className='col-3'><h4>Trie :</h4></div>
                                <div className='col-3'>
                                    <span className='h6'>Année : </span>
                                    <DatePicker 
                                        onChange={(newYear)=>{
                                            setselectedYear(newYear)
                                            filterDeviceByYear(newYear)
                                        }}

                                        format = "y"
                                        value={selectedYear}
                                        disabled = {devicesDatas !="" ? false : true}
                                        maxDetail = "decade"
                                        minDetail = "decade"
                                        showLeadingZeros={false}
                                        calendarIcon = {<Icon icon="bi:calendar" width="20" height="20" hFlip={true} vFlip={true} className="text-light" />}
                                        clearIcon= {<Icon icon="ic:round-clear" width="20" height="20" hFlip={true} vFlip={true} className="text-light"/>}
                                    />
                                </div>
                                <div className='col-3'>
                                    <span className='h6'>Mois : </span>
                                    <DatePicker 
                                        onChange={(newMonth)=>{
                                            setselectedMonth(newMonth)
                                            filterDeviceByMonth(newMonth)
                                        }}
                                        format = "MM"
                                        showLeadingZeros={false}
                                        nativeInputAriaLabel ="ok"
                                        value={selectedMonth}
                                        maxDetail = "year"
                                        minDetail = "year"
                                        disabled = {selectedYear!="" ? false : true}
                                        calendarIcon = {<Icon icon="bi:calendar" width="20" height="20" hFlip={true} vFlip={true} className="text-light" />}
                                        clearIcon= {<Icon icon="ic:round-clear" width="20" height="20" hFlip={true} vFlip={true} className="text-light"/>}
                                    />
                                </div>
                                <div className='col-3'>
                                    <span className='h6'>Jour : </span>
                                    <DatePicker 
                                        onChange={(newDay)=>{
                                            setselectedDay(newDay)
                                            filterDeviceByDay(newDay)
                                        }}
                                        format = "dd"
                                        value={selectedDay}
                                        showLeadingZeros={false}
                                        maxDetail = "month"
                                        minDetail = "month"
                                        disabled = {selectedYear!="" && selectedMonth!="" ? false : true}
                                        calendarIcon = {<Icon icon="bi:calendar" width="20" height="20" hFlip={true} vFlip={true} className="text-light" />}
                                        clearIcon= {<Icon icon="ic:round-clear" width="20" height="20" hFlip={true} vFlip={true} className="text-light"/>}
                                    />
                                </div>
                            </div>

                            <MaterialTable
                                title="Appareils"
                                columns={columns} 
                                data={filteredDeviceData} 
                                
                                isLoading={loading}

                                onSelectionChange={(selectedRows) => console.log(selectedRows)}
                                options={{
                                    sorting: true, search: true,
                                    searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
                                    filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
                                    paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
                                    exportAllData: true, exportFileName: "mHealthDeviceDatas", addRowPosition: "first", actionsColumnIndex: -1, selection: false,
                                    showSelectAllCheckbox: false, showTextRowsSelected: false,
                                    grouping: false, columnsButton: true,
                                }}

                                icons={{ Add: () => <AddIcon /> }}

                                localization={{
                                    body: {
                                        emptyDataSourceMessage: "Pas de téléphone à afficher",
                                        addTooltip: 'Ajouter',
                                        deleteTooltip: 'Supprimer',
                                        editTooltip: 'Editer',
                                        filterRow: {
                                            filterTooltip: 'Filtrer'
                                        },
                                        editRow: {
                                            deleteText: 'Voulez-vous supprimer cette ligne ?',
                                            cancelTooltip: 'Annuler',
                                            saveTooltip: 'Enregistrer'
                                        }
                                    },
                                    grouping: {
                                        placeholder: "Tirer l'entête ...",
                                        groupedBy: 'Grouper par:'
                                    },
                                    header: {
                                        actions: 'Actions'
                                    },
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        labelRowsSelect: 'lignes',
                                        labelRowsPerPage: 'lignes par page:',
                                        firstAriaLabel: 'Première page',
                                        firstTooltip: 'Première page',
                                        previousAriaLabel: 'Page précédente',
                                        previousTooltip: 'Page précédente',
                                        nextAriaLabel: 'Page suivante',
                                        nextTooltip: 'Page suivante',
                                        lastAriaLabel: 'Dernière page',
                                        lastTooltip: 'Dernière page'
                                    },
                                    toolbar: {
                                        addRemoveColumns: 'Ajouter ou supprimer des colonnes',
                                        nRowsSelected: '{0} ligne(s) sélectionée(s)',
                                        showColumnsTitle: 'Voir les colonnes',
                                        showColumnsAriaLabel: 'Voir les colonnes',
                                        exportTitle: 'Exporter',
                                        exportAriaLabel: 'Exporter',
                                        exportName: 'Exporter en CSV',
                                        searchTooltip: 'Chercher',
                                        searchPlaceholder: 'Chercher',
                                        exportPDFName: "Exporter en PDF",
                                        exportCSVName : "Exporter en CSV"
                                    }
                                }}
                            />
                        </div>
                       
                    </div>

            </div>

            <Footer style={{position: "fixed", bottom: 0, top:"auto"}} />

            
        </div>
    );
};

export default DevicesList;



