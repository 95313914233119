import React from 'react';
import { NavLink } from 'react-router-dom';

const BreadCrum = ({name1, name2}) => {
    return (
        <div>
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="breadcrumb-title pe-3  text-light h4">{name1}</div>
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item"><NavLink className="text-light" to="/"><i className="bx bx-home-alt"></i></NavLink>
                            </li>
                            <li className="breadcrumb-item active text-light" aria-current="page">{name2}</li>
                        </ol>
                    </nav>
                </div>
			</div>
        </div>
    );
};

export default BreadCrum;