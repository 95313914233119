import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import NavBar from '../../../components/NavBar';
import SideBar from '../../../components/SideBar';
import useGet from '../../../hooks/useGet';

const PrintCsi = ({change_detect}) => {

    const { datas:districtDatas, loading:loadingDistrict} = useGet("/api/district/", "null")
    const { datas:communeDatas, loading:loadingcommune} = useGet("/api/commune/", "null")
    const { datas:csiDatas, loading:loadingcsi} = useGet("/api/csi/", "null")
    const { datas:villageDatas, loading:loadingvillage} = useGet("/api/village/", "null")
    const { datas:relaisDatas, loading:loadingrelais} = useGet("/api/relais/", "null")
    
    const [communeFilterDatas, setcommuneFilterDatas] = useState([])
    const [csiFilterDatas, setcsiFilterDatas] = useState([])
    const [villageFilterDatas, setvillageFilterDatas] = useState([])
    const [relaisFilterDatas, setrelaisFilterDatas] = useState([])

    const onDistrictSelectButtonChange = async (e)=>{
        e.preventDefault();
        const districtId = e.target.value

        // setTimeout(() => {
        if(districtId!=""){
            communeDatas?.map(commune=>{
                if (commune.district_id==districtId) {
                    setcommuneFilterDatas([...communeFilterDatas, commune])
                    csiDatas?.map(csi=>{
                        if(csi.commune_id==commune.id){
                            setcsiFilterDatas([...csiFilterDatas, csi])
                        }
                    })
                }
            })
        }            
        // }, 1000);

        console.log("---csiFilterDatas---")
        console.log(csiFilterDatas)
        console.log("---communeFilterDatas---")
        console.log(communeFilterDatas)
    }

    const onCsiSelectButtonChange = ()=>{}




    return (
        <div>

                <NavBar change_detect={change_detect} />
                <SideBar/>
                
                <div className="page-wrapper">

                    <div className="page-content">

                            <div>
                                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                                    <div className="breadcrumb-title pe-3 text-light fw-bold">Impression CSI</div>
                                        <div className="ps-3">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb mb-0 p-0">
                                                    <li className="breadcrumb-item"><NavLink to="/"><i className="bx bx-home-alt"></i></NavLink></li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                            </div>


                            <div className='container-fluid'>

                                <div className='container-fluid card p-2'>
                                    <div className='row'>
                                        <div className="form-group col-6">
                                            <fieldset> <legend>Choix district</legend>
                                                    <select onChange={onDistrictSelectButtonChange} id="districtSelectButton" className="form-select form-select-lg" aria-label=".form-select-lg example" style={{color:"white"}}>
                                                        <option selected value="">...</option>
                                                        {
                                                            districtDatas.map(district=>{
                                                                return(
                                                                    <option value={district?.id}>{district?.nom}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                            </fieldset>
                                        </div>
                                        
                                        <div className="form-group col-6">
                                            <fieldset> <legend>Choix CSI</legend>
                                                    <select onChange={onCsiSelectButtonChange} id="csiSelectButton" className="form-select form-select-lg " aria-label=".form-select-lg example" style={{color:"white"}}>
                                                        <option selected value="">...</option>
                                                        <option selected value="district">DISTRICTS</option>
                                                        <option value="commune">COMMUNES</option>
                                                        <option value="csi">CSI</option>
                                                        <option value="village">VILLAGES</option>
                                                        <option value="relais">RELAIS</option>
                                                    </select>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>

                                <div className="card mt-4">

                                </div>

                            </div>

            
            
                    </div>
                </div>
        </div>
    );
};

export default PrintCsi;