import Cookies from 'js-cookie';
import React from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import SideBar from '../components/SideBar';
import "./styles/dashboard.css"

const Unauthorized = () => {
    
    if(!Cookies.get("mhealth_devices_access_token")){
        return (<Navigate to="/login" />)
    }

    return (
        <div>
            <NavBar/>
            <SideBar/>
            
            <div className="page-wrapper mb-0">

            <div className="page-content mb-0">

                    <div className="mb-0">
                        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                                <div className="ps-3">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 p-0">
                                            <li className="breadcrumb-item"><NavLink to="/"><i className="bx bx-home-alt"></i></NavLink></li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                    </div>

            
                    <div className="error-404 d-flex justify-content-center mt-4">
                        <div className="container">
                            <div className="card py-5">
                                <div className="row g-0">
                                    <div className="col col-xl-5">
                                        <div className="card-body p-4">
                                            <h1 className="display-1 text-danger"><span className="">4</span><span className="">0</span><span className="">1</span></h1>
                                            <h2 className="font-weight-bold display-4 text-danger">Erreur !</h2>
                                            <p className="text-light fw-bold">Vous n'etes pas autorisé à effectuer cette action !</p>
                                            <div className="mt-5"> 
                                                <a href="/" className="btn btn-light btn-lg px-md-5 radius-30">Dashboard</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-7  text-center">
                                        <img style={{height:"300px", width:"650px"}} src="https://www.ionos.es/digitalguide/fileadmin/DigitalGuide/Teaser/401-Unauthorized-t.jpg" className="img-fluid" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer style={{position: "fixed", bottom: 0, top:"auto"}} />
        </div>
    );
};

export default Unauthorized;