import axios from "axios"
import Cookies from "js-cookie"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import addNotification from 'react-push-notification'
import logomHealthWhite from "../logomHealthWhite.png"


// Ce hook verifie a chaque temps donné si un nouvel appareil a ete ajoutés 


const GetIfNewDeviceHashBeenAded = () => {

    const [deviceDatas, setdeviceDatas] = useState([])
    const [deviceDatasCount, setdeviceDatasCount] = useState(0)

    const loc = (useLocation()).pathname

    const getIfNewDeviceHashBeenAdded = ()=>{
        const deviceCount = Cookies.get('devices_count') || 0
        if(!loc.includes("/login")){
            axios.get("api/device/")
                    .then((res) => {
                        if(Cookies.get('devices_count') && Object.keys(res?.data?.data).length > deviceCount){
                            setdeviceDatas(res?.data?.data)
                            const deviceAddedCount = Object.keys(res?.data?.data).length - deviceCount
                            addNotification({
                                title: 'Mhealth Device Manager Notification',
                                subtitle: "Ajout d'appareils.",
                                message: deviceAddedCount>1 ? deviceAddedCount + ' nouveaux appareils ont été ajoutés.' : deviceAddedCount + ' nouvel appareil a été ajouté.' ,
                                theme: 'darkblue',
                                native: true,
                                duration: 5000,
                                closeButton: 'Quitter',
                                icon : logomHealthWhite
                            })
                            Cookies.set('devices_count', Object.keys(res?.data?.data).length, { expires: 1 })
                        }
                        else{
                            Cookies.set('devices_count', Object.keys(res?.data?.data).length, { expires: 1 })
                        }
                        setdeviceDatasCount(Object.keys(res?.data?.data).length)
            })
        }
    }

    useEffect(() => {
            setInterval(function() {
                    console.log("Getting new devices ...")
                    getIfNewDeviceHashBeenAdded()
            }, 20000)
    }, [])

    return {deviceDatas}

    
};

export default GetIfNewDeviceHashBeenAded;

