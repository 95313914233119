import React, {useState} from 'react';
import { Navigate,  useNavigate } from 'react-router-dom';
import axios from "axios"
import Cookies from 'js-cookie'
import "./styles/dashboard.css"
import logomHealthWhite from "../logomHealthWhite.png"
import useGet from '../hooks/useGet';

const Login = () => {

    const [response, setResponse] = useState(null);
    const [message, setMessage] = useState('');
    const [loading, setloading] = useState(false);

    const [usernameMessage, setusernameMessage] = useState("")
    const [passwordMessage, setpasswordMessage] = useState("")

    // const { datas:devices} = useGet("api/device/", " ")

    const [messages, setmessages] = useState("")

    const navigate = useNavigate()

    const [errorMessage, seterrorMessage] = useState("")

    const [remember, setremember] = useState(false)


    const [passwordInputType, setpasswordInputType] = useState("password")

    // Afficher ou cacher le mot de passe
    const onPasswordInputTypeChange = ()=>{
        if(passwordInputType=="password"){
            setpasswordInputType("text")
        }
        else{
            setpasswordInputType("password")
        }
    }
          

    const [loginInput, setLoginInput] = useState({
        "username": '',
        "password" : ''
    })
    
    const onLoginInputChange = (e)=>{
      setLoginInput({...loginInput, [e.target.name] : e.target.value})
      setusernameMessage("")
      setpasswordMessage("")
      seterrorMessage("")
    }

    
      const [spinner, setspinner] = useState()

    if(Cookies.get("mhealth_devices_access_token")){
        return (<Navigate to="/" />)
    }

    const OnSubmit = async (e)=>{

        
        e.preventDefault()
        const loginModel = {
          username: loginInput.username,
            password : loginInput.password
        }

        if(loginModel.username===""){
          setusernameMessage("Le nom d'utilisateur est requis")
          setspinner("")
        }
        if(loginModel.password===""){
          setpasswordMessage("Le mot de passe est requis")
          setspinner("")
        }
      
        const url = "api/auth/login/"; 

       if(loginModel.username!=="" && loginModel.password!=="" ){
            setloading(true)

            setspinner(
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            )
            
            //Recupperation de token
            await axios
            .post(url, JSON.stringify(loginModel))
            .then((res) => {
                    setResponse(res)
                    setloading(false)
                    setusernameMessage("")
                    setpasswordMessage("")
                    seterrorMessage("")
                    setspinner("")

                    if(Cookies.set('mhealth_devices_access_token', res.data.data, { expires: 1 })){
                        // Cookies.set('devices_count', Object.keys(devices).length, { expires: 1 })
                        //Recupperation des infos utilisateur
                        axios
                        .get("api/auth/user/" + loginInput.username + "/")
                        .then((res)=>{
                            if(Cookies.set('mhealth_devices_user_infos', JSON.stringify(res.data.data), { expires: 1 })){
                                return (<Navigate to="/"/>)
                            }
                        })
                    }
    
            })
            .catch((err) => {
                setMessage("erreur")
                console.log(err)
                setloading(false)
                if(err?.code==="ERR_BAD_REQUEST" && loginModel.username!=="" && loginModel.password!=="" ){
                    seterrorMessage("Nom d'utilisateur ou mot de passe incorrect")
                    setusernameMessage("")
                    setpasswordMessage("")
                    setspinner("")
                }
                if(err?.response?.data?.code===404 && loginModel.username!=="" && loginModel.password!=="" ){
                    seterrorMessage("Password/username incorrect !")
                    console.log(err)
                    setusernameMessage("")
                    setpasswordMessage("")
                    setspinner("")
                }
                if(err?.code ==="ERR_NETWORK" && loginModel.username!=="" && loginModel.password!==""){
                    seterrorMessage("Probleme lié à la connexion, contactez l'assistance !")
                    setusernameMessage("")
                    setpasswordMessage("")
                    setspinner("")
                } 
                if(err?.code ==="ERR_CONNECTION_REFUSED" && loginModel.username!=="" && loginModel.password!==""){
                    seterrorMessage("Probleme lié au serveur, contactez l'assistance !")
                    setusernameMessage("")
                    setpasswordMessage("")
                    setspinner("")
                }
            })
            .finally(() => {
                setloading(false);
            })
       }
    }

    return (
        <div>
            <div className="wrapper">
                <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
                    <div className="container-fluid">
                        <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                            <div className="col col-sm-6 col-md-8 mx-auto">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="border p-4 rounded">
                                            
                                            <div className="mb-4 text-center">
                                                <img src={logomHealthWhite} width="100" alt="" />
                                                <div className="my-1">MHealth Device Manager</div>
                                            </div>

                                            <div className="text-center mb-4">
                                                <h3 className="fw-bold h3">Connexion</h3>
                                            </div>
                                            <div className="form-body">
                                                <form className="row g-3">
                                                    <div className="col-12">
                                                        <label htmlFor="inputEmailAddress" className="form-label text-light fw-bold">Nom d'utilisateur</label>
                                                        <input onChange={onLoginInputChange} type="text" name="username" className="form-control text-white" id="inputEmailAddress" placeholder="Entrez le nom d'utilisateur"/>
                                                        {
                                                         usernameMessage ?  <small className=" pl-3" style={{ color:"red" }} > { usernameMessage } </small> : ""
                                                        }
                                                    </div>
                                                    <div className="col-12">
                                                        <label htmlFor="inputChoosePassword" className="form-label text-light fw-bold">Mot de passe</label>
                                                        <div className="input-group" id="show_hide_password">
                                                            <input  onChange={onLoginInputChange}  type={passwordInputType} name="password" className="form-control border-end-0 text-white" id="inputChoosePassword" placeholder="Entrez le mot de passe" />
                                                             <a href="" onClick={(e)=> {e.preventDefault(); onPasswordInputTypeChange()}} className="input-group-text bg-transparent" style={{color : "white !important"}} >{ passwordInputType === "password" ? <i class="fa-solid fa-eye" style={{ color : "white" }}></i> : <i class="fa-sharp fa-solid fa-eye-slash"style={{ color : "white" }}></i> }</a>
                                                        </div>
                                                        {
                                                            passwordMessage ? <small className="pl-3" style={{ color:"red" }} > { passwordMessage } </small> : "" 
                                                        } 
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-check form-switch">
                                                            <input onClick={(e)=>{
                                                                    setremember(e.target.checked)
                                                                }
                                                            } className="form-check-input" type="checkbox" id="flexSwitchCheckChecked"/>
                                                            <label className="form-check-label text-light" htmlFor="flexSwitchCheckChecked fw-bold">Se rappeler de moi ?</label>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-6 text-end">	<a href="authentication-forgot-password.html">Forgot Password ?</a></div> */}
                                                    <div className="col-12">
                                                        <div className="d-grid">
                                                            <button onClick={OnSubmit} type="submit" className="btn btn-light fw-bold my-2" style={{color: '#0c675e'}}><i className="bx bxs-lock-open"></i>Se connecter</button>
                                                        </div>
                                                    </div>

                                                    {
                                                        loading ?
                                                        <div className="col-12 mx-2 text-center">
                                                            <div className="spinner-border text-light" role="status">
                                                                <span className="sr-only"></span>
                                                            </div>
                                                        </div> : ""
                                                    }

                                                    {
                                                        errorMessage ?
                                                        <div class="alert alert-danger" role="alert">
                                                            <strong>Erreur !</strong> {errorMessage}
                                                        </div> : ""
                                                    }

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default Login;