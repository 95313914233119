let API_BASE_URL;

switch (window.location.hostname) {
    case 'mdm.mhealth-niger.datacenter.lagfo':
        API_BASE_URL = "http://mdm.mhealth-niger.datacenter.lagfo:444/";
        break;
    case 'mdm.mhealth-africa.org':
        API_BASE_URL = "https://mdmapi.mhealth-africa.org/";
        break;
    default:
        // Définir une URL de secours si le domaine ne correspond à aucun des cas spécifiés
        API_BASE_URL = "https://mdmapi.mhealth-africa.org/";
        break;
}



export default API_BASE_URL;


