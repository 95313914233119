import axios from 'axios';
import Cookies from 'js-cookie';
import MaterialTable from 'material-table';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Navigate, NavLink, useNavigate } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import SideBar from '../../components/SideBar';
import useGet from '../../hooks/useGet';
import "../styles/tablesStyles.css"

const DevicesLostList = ({change_detect_}) => {

    const navigate = useNavigate()
    const [change_detect, setchange_detect] = useState(Math.floor(Math.random() * 10000000) + 1)

    const  { datas:historyDatas, count, error, loading } = useGet("/api/lost/device/", change_detect)  
    // console.log(historyDatas)

    const columns = [
        { title: "Device ID", field: "device_id",filtering: false, filterPlaceholder: "filter", validate: rowData => rowData.device_id === '' ? 'Ce champ ne peut etre vide !' : '' },
        
        { title: "Retrouvé ?", field: "is_retrieved", filtering: true , lookup: { true: "Retrouvé", false: "Non Retrouvé" },  align:"center", 
            render : (rowData)=>(
                rowData.is_installed ? <span className="badge text-bg-success">Retrouvé</span> : <span className="badge text-bg-danger">Non Retrouvé</span> 
            )
        }, 
                
        { title: "Date", field: "date", type: "date",filtering: false, filterPlaceholder: "filter", align:"center", validate: rowData => rowData.date === '' ? 'Ce champ ne peut etre vide !' : '' },
    ]


     //Verifier les privileges superadmin
     const IsAdmin = ()=>{
        if(Cookies.get('mhealth_devices_user_infos')){
            const user_infos_is_admin = (JSON.parse(Cookies.get('mhealth_devices_user_infos'))).is_admin
            if(user_infos_is_admin===true){
                return true
            }
            else{return false}
        } else{return false}
    }


    const formatDate =(date)=>{
        const maDate = new Date(date);
        const annee = maDate.getFullYear();
        const mois = ('0' + (maDate.getMonth() + 1)).slice(-2);
        const jour = ('0' + maDate.getDate()).slice(-2);
        const heures = ('0' + maDate.getHours()).slice(-2);
        const minutes = ('0' + maDate.getMinutes()).slice(-2);
        const secondes = ('0' + maDate.getSeconds()).slice(-2);
        const dateFormatee = `${annee}-${mois}-${jour} ${heures}:${minutes}:${secondes}`;
        return dateFormatee;
    }

    
    if(!Cookies.get("mhealth_devices_access_token")){
        return (<Navigate to="/login" />)
    }
    return (
            <>
                <NavBar change_detect={change_detect_} />
                <SideBar/>
                    
                <div className="page-wrapper">

                    <div className="page-content">
                            <div className='col-10'>
                                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                                    <div className="breadcrumb-title pe-3  text-light h4">Téléphones perdues</div>
                                    <div className="ps-3">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb mb-0 p-0">
                                                <li className="breadcrumb-item"><NavLink className="text-light" to="/"><i className="bx bx-home-alt"></i></NavLink>
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div id='element-to-print' className="mb-3 card">
                                    <MaterialTable
                                        title="Historique"
                                        columns={columns} 
                                        data={historyDatas}
                                        isLoading={loading}

                                        editable={{
                                            onRowAdd: IsAdmin() ? (newRow) => new Promise((resolve, reject) => {
                                                newRow.date!=="" ?
                                                    axios
                                                        .post( `/api/lost/device/` , {
                                                            "device_id" : newRow.device_id,
                                                            "date" : newRow.date!=="" ? formatDate(newRow.date) : "",
                                                        })
                                                        .then((res) => {
                                                            setchange_detect(Math.floor(Math.random() * 10000000) + 1)
                                                            resolve()                                         
                                                        }) :
                                                    axios
                                                        .post( `/api/lost/device/` , {
                                                            "device_id" : newRow.device_id                                                        })
                                                        .then((res) => {
                                                            setchange_detect(Math.floor(Math.random() * 10000000) + 1)
                                                            resolve()                                         
                                                        })


                                            }) : "" ,


                                            // onRowUpdate: IsAdmin() ?  (newRow, oldRow) => new Promise((resolve, reject) => {
                                            //     axios
                                            //         .put( `/api/lost/device/${oldRow.device_id}S` , {
                                            //             "device_id" : newRow.device_id,
                                            //             "is_retrieved" : newRow.is_retrieved,
                                            //             "date" : formatDate(newRow.date)
                                            //         })
                                            //         .then((res) => {
                                            //             setchange_detect(Math.floor(Math.random() * 10000000) + 1)
                                            //             resolve()                                         
                                            //         })
                                            // }):"",


                                            onRowDelete: IsAdmin() ?  (selectedRow) => new Promise((resolve, reject) => {
                                                axios
                                                    .delete( `api/lost/device/${selectedRow.device_id}`)
                                                    .then((res) => {
                                                        setchange_detect(Math.floor(Math.random() * 10000000) + 1)
                                                        resolve()                                         
                                                    })
                                            }):""
                                        }}

                                        onSelectionChange={(selectedRows) => console.log(selectedRows)}
                                        options={{
                                            sorting: true, search: true,
                                            searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
                                            filtering: false, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
                                            paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
                                            exportAllData: true, exportFileName: "mHealthDeviceDatas", addRowPosition: "first", actionsColumnIndex: -1, selection: false,
                                            showSelectAllCheckbox: false, showTextRowsSelected: false,
                                            grouping: false, columnsButton: true,
                                        }}

                                        localization={{
                                            body: {
                                                emptyDataSourceMessage: "Pas de téléphones perdus à afficher",
                                                addTooltip: 'Ajouter',
                                                deleteTooltip: 'Supprimer',
                                                editTooltip: 'Editer',
                                                filterRow: {
                                                    filterTooltip: 'Filtrer'
                                                },
                                                editRow: {
                                                    deleteText: 'Voulez-vous supprimer cette ligne?',
                                                    cancelTooltip: 'Annuler',
                                                    saveTooltip: 'Enregistrer'
                                                }
                                            },
                                            grouping: {
                                                placeholder: "Tirer l'entête ...",
                                                groupedBy: 'Grouper par:'
                                            },
                                            header: {
                                                actions: 'Actions'
                                            },
                                            pagination: {
                                                labelDisplayedRows: '{from}-{to} de {count}',
                                                labelRowsSelect: 'lignes',
                                                labelRowsPerPage: 'lignes par page:',
                                                firstAriaLabel: 'Première page',
                                                firstTooltip: 'Première page',
                                                previousAriaLabel: 'Page précédente',
                                                previousTooltip: 'Page précédente',
                                                nextAriaLabel: 'Page suivante',
                                                nextTooltip: 'Page suivante',
                                                lastAriaLabel: 'Dernière page',
                                                lastTooltip: 'Dernière page'
                                            },
                                            toolbar: {
                                                addRemoveColumns: 'Ajouter ou supprimer des colonnes',
                                                nRowsSelected: '{0} ligne(s) sélectionée(s)',
                                                showColumnsTitle: 'Voir les colonnes',
                                                showColumnsAriaLabel: 'Voir les colonnes',
                                                exportTitle: 'Exporter',
                                                exportAriaLabel: 'Exporter',
                                                exportName: 'Exporter en CSV',
                                                searchTooltip: 'Chercher',
                                                searchPlaceholder: 'Chercher'
                                            }
                                        }}

                                    />
                                </div>
                            </div>
                    </div>
                </div>
                {/* <Footer className="fixed-bottom"  /> */}
            </>
    );
};

export default DevicesLostList;