import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import NavBar from '../components/NavBar';
import SideBar from '../components/SideBar';
import "./styles/dashboard.css"
import { PulseLoader } from 'react-spinners';
import EnrolDeviceCharts from '../components/EnrolDeviceCharts';
import FlotteStateChart from '../components/FlotteStateChart';
import { Oval } from 'react-loader-spinner';
import axios from 'axios';
import useGet from '../hooks/useGet';



const Dashboard = ({change_detect}) => {
    
    // const [deviceData, setdeviceData] = useState([])
    const navigate = useNavigate()
    
    // axios.get("api/device/")
    // .then((res) => {
    //     setdeviceData(res.data.data)
    // }).
    // catch(err => {        
    //     if(err.response?.data?.message==="JWT signature Expired"){
    //         Cookies.remove('mhealth_devices_user_infos')
    //         Cookies.remove('mhealth_devices_access_token')
    //         navigate("/login")
    //     }
    // })

    const  { datas:deviceData, count, error, loading } = useGet("api/device/", change_detect)  

   
    useEffect(() => {
        console.log(change_detect)
    }, [change_detect])
    
      
      
    const [historiqueData, sethistoriqueData] = useState([])

    const historiqueLoading = deviceData != [] ? false : true

    const deviceCount = Object.keys(deviceData).length

    const activateDevices = deviceData?.filter(d => d.is_activate===true)
    const activateDevicesCount = Object.keys(activateDevices).length 
    

    const relaisDevices = deviceData?.filter(d => d.is_relais===true)
    const relaisDevicesCount = Object.keys(relaisDevices).length

    const relais_csiDevices = deviceData?.filter(d => d.is_relais===true && d.is_csi===true)
    const relais_csiDevicesCount = Object.keys(relais_csiDevices).length

    const csiDevices = deviceData?.filter(d => d.is_csi===true)
    const csiDevicesCount = Object.keys(csiDevices).length

    

    if(!Cookies.get("mhealth_devices_access_token")){
        return (<Navigate to="/login" />)
    }

    return (
        <div className="">

        <NavBar change_detect={change_detect} />
        <SideBar/>

            <div className="page-wrapper">
                <div className="page-content">

                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-4">

                            <div className="col">
                                <div className="card radius-10 cardHoverEffect">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <p className="mb-0 text-white h5 mr-2">Appareils enrolés</p>
                                                <h4 className="my-1 text-white">
                                                    {
                                                        deviceCount || 
                                                        <div className="spinner-border text-light mt-1" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    }
                                                </h4>
                                            </div>
                                            <div className="widgets-icons bg-light-transparent text-white ms-auto">
                                                <iconify-icon icon="bx:devices" width="40" height="40"></iconify-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card cardHoverEffect radius-10">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <p className="mb-0 text-white h5">Appareils activés</p>
                                                <h4 className="my-1 text-white">
                                                    {
                                                        activateDevicesCount || 
                                                        <div className="spinner-border text-light mt-1" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    }
                                                </h4>
                                            </div>
                                            <div className="widgets-icons bg-light-transparent text-white ms-auto ms-2">
                                                <iconify-icon icon="pixelarticons:device-vibrate" width="40" height="40"></iconify-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card cardHoverEffect radius-10">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <p className="mb-0 text-white h5">Appareils CSI</p>
                                                <h4 className="my-1 text-white mt-1">
                                                    {
                                                        csiDevicesCount || 
                                                        <div className="spinner-border text-light mt-1" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    }
                                                </h4>
                                            </div>
                                            <div className="widgets-icons bg-light-transparent text-white ms-auto"><iconify-icon icon="typcn:device-tablet" width="40" height="40"></iconify-icon></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card cardHoverEffect radius-10">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div>
                                                <p className="mb-0 text-white h5">Appareils RELAIS</p>
                                                <h4 className="my-1 text-white">
                                                    {
                                                        relaisDevicesCount || 
                                                        <div className="spinner-border text-light mt-1" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    }
                                                </h4>
                                            </div>
                                            <div className="widgets-icons bg-light-transparent text-white"><iconify-icon icon="typcn:device-phone" width="40" height="40"></iconify-icon></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row container-fluid pt-3' >
                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className="card radius-10 p-3 ">  
                            {
                                (csiDevicesCount && relaisDevicesCount && relais_csiDevicesCount && deviceCount) ?                           
                                <EnrolDeviceCharts datas={deviceData} /> : 

                                <div className="container text-center d-flex justify-content-center jumbotron d-flex align-items-center ">
                                    <center className='row '>
                                        <Oval
                                        height={100} 
                                        width={100}
                                        color="#0c675e"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel='oval-loading'
                                        secondaryColor="#fff"
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}

                                        />
                                    </center>
                                </div>
                            } 
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className="card radius-10 p-3 ">
                            {
                                (csiDevicesCount && relaisDevicesCount && relais_csiDevicesCount && deviceCount) ?
                                            <FlotteStateChart csi={csiDevicesCount} relais={relaisDevicesCount} csi_relais={relais_csiDevicesCount} total={deviceCount} />  :
                                            
                                            <div className="container text-center d-flex justify-content-center jumbotron d-flex align-items-center ">
                                                <center className='row '>
                                                    <Oval
                                                    height={100} 
                                                    width={100}
                                                    color="#0c675e"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel='oval-loading'
                                                    secondaryColor="#fff"
                                                    strokeWidth={2}
                                                    strokeWidthSecondary={2}

                                                    />
                                                </center>
                                            </div>
                                            
                            }
                            </div>
                        </div>
                    </div>

			
                    {/* {historiqueTable(historiqueLoading, historiqueData)} */}









                </div>
            </div>
    );
};

export default Dashboard;

function historiqueTable(historiqueLoading, historiqueData) {
    return <div className="card radius-10 m-4">
        <div className="card-body">
            <div className="d-flex align-items-center">
                <div>
                    <h5 className="mb-0">Activités récentes</h5>
                </div>
                <div className="font-22 ms-auto"><i className='bx bx-history'></i>
                </div>
            </div>
            <hr />
            <div className="table-responsive">
                <table className="table align-middle mb-0">
                    <thead className="table-light">
                        <tr className="text-center">
                            <th>Utilisateur</th>
                            <th>Action</th>
                            {/* <th>Description</th> */}
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {historiqueLoading == true ? <td className="text-center mt-4 mb-4" colSpan="3"><center><PulseLoader color="#36d7b7" /></center></td> :
                            historiqueData.slice(0, 10).map(data => {
                                return (
                                    <tr key={data.id}>
                                        <td>{data.user}</td>
                                        <td>{(data.task).slice(0, 17) + " . . ."}</td>
                                        <td>{data.date}</td>
                                    </tr>
                                );
                            }).sort(function (a, b) {
                                return a.date - b.date;
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    </div>;
}
