import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import useGet from '../../hooks/useGet';
import MaterialTable from 'material-table';
import axios from 'axios';
import QRCode from "react-qr-code";
import { IconButton } from '@material-ui/core';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';


const Csi = ({csiDatas, communeDatas, localization, loading}) => {

    const Datas = csiDatas

    const navigate = useNavigate()    

    const columns = [

        { title: "ID", field: "id",filtering: false, filterPlaceholder: "filter", emptyValue: () => <em>-</em> },
        
        // { title: "UID", field: "uid",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em> },
        
        { title: "Code", field: "code",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em> },
        
        { title: "Nom", field: "nom",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em> },
        
        { title: "Commune", field: "commune_id",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em>,
            render: (rowDatas) =>{
                return communeDatas.map(commune=>{
                    if(commune.id==rowDatas.commune_id ){const nom = commune.nom; return <span>{nom}</span> }
                })
            }
        },
        
        { title: "Code QR", field:"id", export: true,filtering: false, filterPlaceholder: "filter", align:"center", render: (row) => <QRCode size={100} value={row.id} />},
              

        {
            title : "", align : "right",
            render : (rowData)=>(
                <>                    
                    <IconButton  title="Afficher les villages de ce CSI ?" style={{color : "#fff"}} aria-label="add an alarm">
                        <RemoveRedEye 
                            onClick={(e)=>{ 
                                const url = "/imprimer/csi/" + rowData.id + "/villages"
                                e.preventDefault(); 
                                navigate(url)
                            }} 
                        />
                    </IconButton>
                </>
            )
        }

        
    ]


    return (
        <div>
            <MaterialTable
                title="CSI"
                columns={columns} 
                data={Datas}
                                
                isLoading={loading}

                localization={localization}
                
                
                onSelectionChange={(selectedRows) => console.log(selectedRows)}
                options={{
                    sorting: true, search: true,
                    searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
                    filtering: false, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
                    paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
                    exportAllData: true, exportFileName: "Liste des CSI", addRowPosition: "first", actionsColumnIndex: -1, selection: false,
                    showSelectAllCheckbox: false, showTextRowsSelected: false,
                    grouping: false, columnsButton: true,
                }}

            />
        </div>
    );
};

export default Csi;