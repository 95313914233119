import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Navigate, NavLink, useNavigate, useParams } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import SideBar from '../../components/SideBar';
import { Map as PigeonMap, Marker as PigeonMarker, ZoomControl as PigeonZoomControl } from "pigeon-maps"
import axios from 'axios';
import dateFormat from "dateformat"
import useGet from '../../hooks/useGet';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { io } from "socket.io-client";
import API_BASE_URL from '../../hooks/apiBaseUrl';




const Localisation = ({ change_detect_ }) => {

    const [socketId, setSocketId] = useState("");

    const { device_id } = useParams()

    const [longitude, setlongitude] = useState("")
    const [latitude, setlatitude] = useState("")
    const [localisationDatas, setlocalisationDatas] = useState("")
    const [name, setname] = useState("")
    const [localisationDate, setlocalisationDate] = useState("")
    const [reloadMap, setreloadMap] = useState(false)
    const [change_detect, setchange_detect] = useState("")
    const [mapZoom, setmapZoom] = useState(10)
    const [batteryLevel, setbatteryLevel] = useState("")
    const [positionInfos, setpositionInfos] = useState("")
    const navigate = useNavigate()


    const [hue, setHue] = useState(0)
    const color = `hsl(${hue % 360}deg 39% 70%)`

    const { datas: device } = useGet("/api/device/" + device_id + "/")

    useEffect(() => {
        const socket = io(API_BASE_URL, {
            // transports: ['websocket', 'polling'],
            path: "/",
        });

        // Get the socket id
        socket.on('connect', (data) => {
            console.log(data)
            setSocketId(socket.id);
        });

        // listen for chat events
        socket.on("onMapUpdated", (data) => {
            setchange_detect(Math.floor(Math.random() * 10000000) + 1)
            setbatteryLevel(data.battery)
            setpositionInfos(data?.info_position)
            NotificationManager.success('La position a été actualisée', "Mise à jour position");
        })

        // when component unmounts, disconnect
        return (() => {
            socket.disconnect()
        })
    }, [])



    const getLocalisation = async (device_id) => {

        await axios.get("/api/localisation/device/" + device_id + "/").
            then(response => {
                setlongitude(response?.data?.data?.longitude)
                setlatitude(response?.data.data?.latitude)
                setlocalisationDatas(response?.data.data)
                setname(response?.data.data.nom)
                setlocalisationDate(dateFormat(response?.data.data.date_creation, "dd/mm/yyyy à H:MM"))
            }).
            catch(err => {
                // redirect('/404')
            })
    }

    useEffect(() => {
        getLocalisation(device_id)
    }, [change_detect])

    const [timeOut, settimeOut] = useState(false)
    setTimeout(() => { settimeOut(true) }, 1000)



    // Fonction de mise a jour de la localisation
    const ReloadMap = (device_id, sid) => {
        setreloadMap(true)
        axios
            .get("/api/localisation/update/device/" + device_id + "/sid/" + sid)
            .then((res) => {
                console.log("Requete envoyee !")
                setreloadMap(false)
                NotificationManager.info('La demande de mise à jour a été effectuée, vous serez averti lorsque la position est actualisée', "Mise à jour position");
            })
            .catch((err) => {
                console.log(err)
                if (err.response.data.code == 401) {
                    console.log("unhautorized")
                    setreloadMap(false)
                    navigate("/unauthorized")
                }
            })
        setreloadMap(false)
    }



    if (!Cookies.get("mhealth_devices_access_token")) {
        return (<Navigate to="/login" />)
    }



    return (
        <div>
            <NavBar change_detect={change_detect_} />
            <SideBar />


            <div className="page-wrapper">

                <div className="page-content">

                    <div>
                        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                            <div className="breadcrumb-title text-light h4 pe-3">Appareils</div>
                            <div className="ps-3">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 p-0">
                                        <li className="breadcrumb-item"><NavLink className="text-light" to="/"><i className="bx bx-home-alt"></i></NavLink>
                                        </li>
                                        <li className="breadcrumb-item active text-light text-decoration-none fw-bold" aria-current="page"><NavLink className="text-light" to="/device/list">Liste</NavLink></li>
                                        <li className="breadcrumb-item active text-light" aria-current="page">appareil</li>
                                        <li className="breadcrumb-item active text-light" aria-current="page">1234</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="card ">
                            <div className='col p-2'>
                                <span className="m-2 text-light ms-3">
                                    {
                                        !reloadMap ?
                                            <button disabled={socketId != "" ? false : true} onClick={(e) => {
                                                setreloadMap(true)
                                                // console.log(socketId)
                                                ReloadMap(device_id, socketId)
                                                console.log(positionInfos)
                                            }} type="button" className="btn btn-outline-light waves-effect me-2">
                                                <i class="fa fa-refresh" aria-hidden="true"></i> Actualiser
                                            </button> :
                                            <div className="spinner-border text-light me-2 mt-0" role="status">
                                                <span className="sr-only">Veuillez patienter !</span>
                                            </div>
                                    }
                                </span>
                                <span className="text-light">
                                    Derniere position : {localisationDate || "indisponible"}
                                </span>

                                {
                                    positionInfos &&

                                    <span>
                                        <div class="vr mx-3" style={{ height: "30px" }}></div>
                                        {
                                            positionInfos?.pays &&
                                            <span>
                                                <span className='mt-2' style={{ fontSize: "20px" }}>
                                                    <i class="fa-solid fa-earth-africa"></i>
                                                    {positionInfos.pays}
                                                </span>
                                            </span>
                                        }
                                        {
                                            positionInfos?.ville &&
                                            <span>
                                                <span className='mt-2' style={{ fontSize: "20px" }}>
                                                    <i class="fa-solid fa-city"></i>
                                                    {positionInfos.ville}
                                                </span>
                                            </span>
                                        }
                                        {
                                            positionInfos?.quartier &&
                                            <span>
                                                <span className='mt-2' style={{ fontSize: "20px" }}>
                                                    <i class="fa-duotone fa-road"></i>
                                                    {positionInfos.quartier}
                                                </span>
                                            </span>
                                        }
                                    </span>

                                }


                                {batteryLevel ?
                                    <>
                                        <div class="vr mx-3" style={{ height: "30px" }}></div>
                                        <span className="m-2 text-light">
                                            {
                                                parseInt(batteryLevel.replace("%", "")) <= 5 ?
                                                    <i class="fa-regular fa-battery-exclamation me-2 my-auto" style={{ fontSize: "30px" }}></i> : "" ||

                                                        parseInt(batteryLevel.replace("%", "")) == 100 ?
                                                        <i class="fa-solid fa-battery-full me-2 my-auto" style={{ fontSize: "30px" }}></i> : "" ||

                                                            parseInt(batteryLevel.replace("%", "")) >= 90 ?
                                                            <i class="fa-solid fa-battery-three-quarters me-2 my-auto" style={{ fontSize: "30px" }}></i> : "" ||

                                                                parseInt(batteryLevel.replace("%", "")) >= 50 ?
                                                                <i class="fa-solid fa-battery-half me-2 my-auto" style={{ fontSize: "30px" }}></i> : "" ||

                                                                    parseInt(batteryLevel.replace("%", "")) >= 20 ?
                                                                    <i class="fa-solid fa-battery-quarter me-2 my-auto" style={{ fontSize: "30px" }}></i> : "" ||

                                                                        parseInt(batteryLevel.replace("%", "")) >= 10 ?
                                                                        <i class="fa-solid fa-battery-low me-2 my-auto" style={{ fontSize: "30px" }}></i> : ""



                                            }
                                            <span className='mt-2' style={{ fontSize: "20px" }}>{batteryLevel}</span>
                                        </span>
                                    </> : ""
                                }

                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div style={{ width: "100%", height: "68vh" }} id="simple-map" className="gmaps">

                                    {
                                        localisationDatas ?
                                            <div className="mapsidebar">
                                                Longitude: {longitude} | Latitude: {latitude} | Default Zoom: 10
                                            </div> : ""
                                    }

                                    {
                                        timeOut && localisationDatas ?



                                            <PigeonMap height="68vh" defaultCenter={[latitude, longitude]} defaultZoom={mapZoom}>
                                                <PigeonZoomControl />
                                                <PigeonMarker
                                                    width={50}
                                                    anchor={[latitude, longitude]}
                                                    color={color}
                                                    onClick={() => setHue(hue + 20)}
                                                >
                                                    <div className="container">
                                                        <div className="row ">
                                                            <center>
                                                                <iconify-icon icon="logos:google-maps" width="35"></iconify-icon>
                                                                <div className="fw-bold h4" style={{ color: "black" }} > {name || ""} </div>
                                                            </center>
                                                        </div>
                                                    </div>
                                                </PigeonMarker>
                                            </PigeonMap>



                                            : <h3 style={{ color: "black", paddingLeft: "40%", paddingTop: "20%" }} className="text-center m-auto">Position indisponible</h3>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );
};

export default Localisation;