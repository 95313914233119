import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import SideBar from '../../components/SideBar';
import { Navigate, NavLink, useNavigate, useParams } from 'react-router-dom';
import useGet from '../../hooks/useGet';
import Swal from 'sweetalert2';
import axios from 'axios';
import { PacmanLoader } from 'react-spinners';
import Cookies from 'js-cookie';
import { Oval } from  'react-loader-spinner'
import dateFormat, { masks } from "dateformat"
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


const DeviceDetails = ({change_detect_}) => {
    // AxiosInterceptorNavigate()

    const navigate = useNavigate()

    let { device_id } = useParams()

    const [change_detect, setchange_detect] = useState("")
    const { datas:datasDevice, count, error:deviceError, loading, status } = useGet("api/device/" + device_id + "/", change_detect) 

                               
    
    //Verifier les privileges superadmin
    const IsAdmin = ()=>{
        if(Cookies.get('mhealth_devices_user_infos')){
            const user_infos_is_admin = (JSON.parse(Cookies.get('mhealth_devices_user_infos'))).is_admin
            if(user_infos_is_admin===true){
                return true
            }
            else{return false}
        } else{return false}
    }


    
    if(status==404){
        navigate("/404")
    }
    if(status==505){
        navigate("/505")
    }


    const { datas:relaisDatas} = useGet("/api/relais/" + datasDevice?.relais_id + "/" , "null")
    const { datas:csiDatas} = useGet("/api/csi/" + datasDevice?.csi_id + "/" , "null")
    const { datas:villageDatas} = useGet("/api/village/" + relaisDatas?.village_id + "/", "null")
    const { datas:communeDatas} = useGet("/api/commune/" + csiDatas?.commune_id + "/", "null")
    const { datas:districtDatas} = useGet("/api/district/" + communeDatas?.district_id + "/" , "null")


    const [setdeviceloading, set_setdeviceloading] = useState("")

    const DesenroleDevice = (device_id)=>{
        Swal.fire({
            title: 'Etes-vous sur(e) ?',
            text: "Vous etes entrain de vous désenroler cet appareil, action irréversible !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui !',
            cancelButtonText : "Annuller"
            }).then((result) => { 
                if (result.isConfirmed) {                                      
                    set_setdeviceloading(<div className=" m-3"><PacmanLoader size={18} color="#ffffff"  /></div>)
                    axios
                    .delete("/api/device/" + device_id + "/")
                    .then((res) => {
                        set_setdeviceloading("")
                        navigate("/device/list")
                    }) 
                    .catch((err) => {
                        console.log(err)
                        if(err.response.data.code==401){
                            console.log("unhautorized")
                            navigate("/unauthorized")
                        }
                    }) 
                }                                          
            })
    } 


    const DesactivateDevice = (device_id)=>{
        Swal.fire({
            title: 'Etes-vous sur(e) ?',
            text: "Vous etes entrain de vous désactiver cet appareil !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui !',
            cancelButtonText : "Annuller"
            }).then((result) => { 
                if (result.isConfirmed) {                                      
                    set_setdeviceloading(<div className=" m-3"><PacmanLoader size={18} color="#ffffff"  /></div>)
                    axios
                    .put("/api/device/" + device_id + "/desactivate/")
                    .then((res) => {
                        set_setdeviceloading("")
                        setchange_detect(Math.floor(Math.random() * 10000000) + 1)
                    }) 
                    .catch((err) => {
                        console.log(err)
                        if(err.response.data.code==401){
                            console.log("unhautorized")
                            navigate("/unauthorized")
                        }
                    })
                }                                          
            })
    } 

    const ActivateDevice = (device_id)=>{
        Swal.fire({
            title: 'Etes-vous sur(e) ?',
            text: "Vous etes entrain de vous d'activer cet appareil !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui !',
            cancelButtonText : "Annuller"
            }).then((result) => { 
                if (result.isConfirmed) {                                      
                    set_setdeviceloading(<div className=" m-3"><PacmanLoader size={18} color="#ffffff"  /></div>)
                    axios
                    .put("/api/device/" + device_id + "/activate/")
                    .then((res) => {
                        set_setdeviceloading("")
                        setchange_detect(Math.floor(Math.random() * 10000000) + 1)
                    })
                    .catch((err) => {
                        console.log(err)
                        if(err.response.data.code==401){
                            console.log("unhautorized")
                            navigate("/unauthorized")
                        }
                    })  
                }                                          
            })
    } 


    const localisationURL = "/device/"+ datasDevice.device_id  +"/location" 
    const historiqueURL = "/device/"+ datasDevice.relais_id  +"/historique" 

    const redirect = useNavigate()

    
    if(!Cookies.get("mhealth_devices_access_token")){
        return (<Navigate to="/login" />)
    }
    // console.log(loading)

    if(loading){
        return (
        <>
            <NavBar change_detect={change_detect_} />
            <SideBar/>
            <div className="page-wrapper">

                <div className="page-content">
                    <div>
                        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                            <div className="breadcrumb-title pe-3">Appareils</div>
                            <div className="ps-3">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 p-0">
                                        <li className="breadcrumb-item"><NavLink to="/"><i className="bx bx-home-alt"></i></NavLink>
                                        </li>
                                        <li className="breadcrumb-item active text-light" aria-current="page"><NavLink className="text-light fw-bold" to="/device/list">Liste</NavLink></li>
                                        <li className="breadcrumb-item active text-light" aria-current="page">appareil</li>
                                        <li className="breadcrumb-item active text-light" aria-current="page">{device_id}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>  
                    <div className="container text-center d-flex justify-content-center jumbotron d-flex align-items-center min-vh-100">
                        <center className='row '>
                            <Oval
                            height={300} 
                            width={300}
                            color="#0c675e"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#fff"
                            strokeWidth={2}
                            strokeWidthSecondary={2}

                            />
                        </center>
                    </div>
                </div>
            </div>
        </>
        )
    }

    return (
                datasDevice ? 
                <>
                    <NavBar/>
                    <SideBar/>
                        
            
                    <div className="page-wrapper">

                    <div className="page-content">

                            <div>
                                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                                    <div className="breadcrumb-title pe-3">Appareils</div>
                                        <div className="ps-3">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb mb-0 p-0">
                                                    <li className="breadcrumb-item"><NavLink to="/"><i className="bx bx-home-alt"></i></NavLink>
                                                    </li>
                                                    <li className="breadcrumb-item active text-light" aria-current="page"><NavLink className="text-light fw-bold" to="/device/list">Liste</NavLink></li>
                                                    <li className="breadcrumb-item active text-light" aria-current="page">appareil</li>
                                                    <li className="breadcrumb-item active text-light" aria-current="page">{device_id}</li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                            </div>                            


                            <section className="h-100 gradient-custom">
                                <div className="container py-1 h-100">
                                <div className="row d-flexh-100">
                                    <div className="col-lg-12 col-xl-12">
                                    <div className="card bg-transparent" style={{borderRadius: "10px"}}>
                                        <div className="card-header px-2 py-1">
                                            <div className="container-fluid  m-3">
                                                <div className="float-right">
                                                

                                                    {
                                                        setdeviceloading ? setdeviceloading :
                                                        <div style={{display : "block"}}>
                                                            <>
                                                            {
                                                                datasDevice.is_activate && IsAdmin() && datasDevice.is_installed  && (datasDevice.is_relais || datasDevice.is_csi)  ?    
                                                                <a className="btn text-light bg-warning me-2"  href="#" role="button"
                                                                onClick={(e)=>{e.preventDefault(); DesactivateDevice(device_id)}}
                                                                >
                                                                    <i className="fas fa-toggle-off"></i>
                                                                        Desactiver
                                                                </a> : ""
                                                            }
                                                            </>
                                                            <>
                                                            {
                                                                
                                                                !datasDevice.is_activate && IsAdmin() && datasDevice.is_installed  && (datasDevice.is_relais || datasDevice.is_csi)  ?
                                                                <a className="btn text-light bg-warning me-2"  href="#" role="button"
                                                                onClick={(e)=>{e.preventDefault(); ActivateDevice(device_id)}}
                                                                >
                                                                    <i className="fas fa-toggle-on"></i>
                                                                        Activer
                                                                </a> : ""
                                                            }
                                                            </>
                                                            
                                                        
                                                            {
                                                                 IsAdmin() ?
                                                                <a className="btn text-light  bg-danger me-2" href="#!" role="button"
                                                                onClick={(e)=>{e.preventDefault(); DesenroleDevice(device_id)}}    
                                                                >  
                                                                    <i className="fas fa-minus-circle"></i>
                                                                    Desenroler
                                                                </a> : ""
                                                            }

                                                            <NavLink className="btn text-light btn-info" to={localisationURL}  role="button">
                                                                <i className="fas fa-map-marked-alt"></i>
                                                                Dernière position
                                                            </NavLink>

                                                            {/* <NavLink className=" ms-2 btn text-light btn-secondary" to={historiqueURL}  role="button">
                                                                <i className="fa fa-list"></i>
                                                                Historique
                                                            </NavLink> */}
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>


                                        <div className="card-body p-4">
                                        <div className="d-flex justify-content-between align-items-center mb-4">
                                            <p className="lead fw-normal mb-0" style={{color: "white"}}>ID : {datasDevice?.device_id}</p>
                                            <p className="small text-light mb-0"  style={{color: "white"}}>Enrolé le : {dateFormat(datasDevice?.date_creation, "mm/dd/yyyy à H:MM")}</p>
                                        </div>

                                        <div className="card shadow-0 border mb-4">
                                            <div className="card-body">
                                            <div className="row">
                                                
                                                <div className="row ">                          
                                                <div className="col-6"><strong className="m-3 h4">{datasDevice?.name || "-"}</strong></div>
                                                {/* {datasDevice?.description && <div className="col-6 text-light">{datasDevice?.description}</div>} */}
                                                </div> <hr/>

                                                <div className="container m-3">
                                                <div className="row mt-3">
                                                    <div className="col-6 text-light">Applications :</div>
                                                    <div className="col-6 text-light">
                                                        {datasDevice?.is_relais && datasDevice?.is_csi ? "RELAIS - CSI" : ""}
                                                        {!datasDevice?.is_relais && !datasDevice?.is_csi ? "-" : ""}
                                                        {datasDevice?.is_relais && !datasDevice?.is_csi ? "RELAIS" : ""}
                                                        {datasDevice?.is_csi  && !datasDevice?.is_relais? "CSI" : ""} 
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-6 text-light">Etat :</div>
                                                    <div className="col-6 text-light">{datasDevice?.is_activate ? <span className="badge badge-pill badge-success">Activé</span> : <span className="badge badge-pill badge-danger">Non activé</span>}</div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-6 text-light">Activé le :</div>
                                                    <div className="col-6 text-light"> {dateFormat(datasDevice?.date_activated, "mm/dd/yyyy à H:MM") || "-"} </div>
                                                </div>
                                                </div>

                                            </div>
                                            
                                            </div>
                                        </div>


                                        <div className="card">
                                            <div className="card-body">
                                            <h5 className="card-title">Autres details</h5>
                                            <hr/>
                                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                                
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="flush-headingTwo">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#RELAISflush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                        RELAIS
                                                        </button>
                                                    </h2>
                                                    <div id="RELAISflush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <div className="row mt-3">
                                                                <div className="col-6 ">ID :</div>
                                                                <div className="col-6 ">{relaisDatas?.id || "-"}</div>
                                                            </div><hr/>
                                                            <div className="row mt-3">
                                                                <div className="col-6 ">Nom :</div>
                                                                <div className="col-6 ">{relaisDatas?.nom || "-"}</div>
                                                            </div><hr/>
                                                            <div className="row mt-3">
                                                                <div className="col-6 ">Prenom :</div>
                                                                <div className="col-6 ">{relaisDatas?.prenom || "-"}</div>
                                                            </div><hr/>
                                                            <div className="row mt-3">
                                                                <div className="col-6 ">Village :</div>
                                                                <div className="col-6 ">{villageDatas?.nom || "-"}</div>
                                                            </div><hr/>
                                                            <div className="row mt-3">
                                                                <div className="col-6 ">Numero :</div>
                                                                <div className="col-6 ">{relaisDatas?.numero || "-"}</div>
                                                            </div><hr/>
                                                            <div className="row mt-3">
                                                                <div className="col-6 ">Langue :</div>
                                                                <div className="col-6 ">{relaisDatas?.langue || "-"}</div>
                                                            </div><hr/>
                                                            <div className="row mt-3">
                                                                <div className="col-6 ">Date :</div>
                                                                <div className="col-6 ">{dateFormat(relaisDatas?.date_creation, "mm/dd/yyyy à H:MM") || "-"}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="flush-headingTwo">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#VILLAGEflush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                        VILLAGE
                                                        </button>
                                                    </h2>
                                                    <div id="VILLAGEflush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <div className="row mt-3">
                                                                <div className="col-6 ">ID :</div>
                                                                <div className="col-6 ">{villageDatas?.id || "-"}</div>
                                                            </div><hr/>
                                                            <div className="row mt-3">
                                                                <div className="col-6 ">Nom :</div>
                                                                <div className="col-6 ">{villageDatas?.nom || "-"}</div>
                                                            </div><hr/>
                                                            <div className="row mt-3">
                                                                <div className="col-6 ">Relais :</div>
                                                                <div className="col-6 ">{relaisDatas?.nom || "-"}</div>
                                                            </div><hr/>
                                                            <div className="row mt-3">
                                                                <div className="col-6 ">Date :</div>
                                                                <div className="col-6 ">{dateFormat(villageDatas?.date_creation, "mm/dd/yyyy à H:MM") || "-"}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="flush-headingOne">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#CSIflush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                        CSI
                                                        </button>
                                                    </h2>
                                                    <div id="CSIflush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <div className="row mt-3">
                                                                <div className="col-6 ">ID :</div>
                                                                <div className="col-6 ">{csiDatas?.id || "-"}</div>
                                                            </div><hr/>
                                                            <div className="row mt-3">
                                                                <div className="col-6 ">Nom :</div>
                                                                <div className="col-6 ">{csiDatas?.nom || "-"}</div>
                                                            </div><hr/>
                                                            <div className="row mt-3">
                                                                <div className="col-6 ">Commune :</div>
                                                                <div className="col-6 ">{communeDatas?.nom || "-"}</div>
                                                            </div><hr/>
                                                            <div className="row mt-3">
                                                                <div className="col-6 ">Responsable :</div>
                                                                <div className="col-6 ">{csiDatas?.responsable_id || "-"}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="flush-headingOne">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#COMMUNEflush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                        COMMUNE
                                                        </button>
                                                    </h2>
                                                    <div id="COMMUNEflush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <div className="row mt-3">
                                                                <div className="col-6 ">ID :</div>
                                                                <div className="col-6 ">{communeDatas?.id || "-"}</div>
                                                            </div><hr/>
                                                            <div className="row mt-3">
                                                                <div className="col-6 ">Nom :</div>
                                                                <div className="col-6 ">{communeDatas?.nom || "-"}</div>
                                                            </div><hr/>
                                                            <div className="row mt-3">
                                                                <div className="col-6 ">District :</div>
                                                                <div className="col-6 ">{districtDatas?.nom || "-"}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="flush-headingThree">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DISTRICTflush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                            DISTRICT
                                                        </button>
                                                    </h2>
                                                    <div id="DISTRICTflush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <div className="row mt-3">
                                                                <div className="col-6 ">ID :</div>
                                                                <div className="col-6 ">{districtDatas?.id || "-"}</div>
                                                            </div><hr/>
                                                            <div className="row mt-3">
                                                                <div className="col-6 ">Nom :</div>
                                                                <div className="col-6 ">{districtDatas?.nom || "-"}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            </div>
                                        </div>                                    
                            
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </section>
                        </div>
                    </div>



            <Footer/>

                
                    
        </> : <Navigate to="/404" /> 
        
        
    );
       
};

export default DeviceDetails;