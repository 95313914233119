import React, { useEffect, useState } from 'react';
import { HashRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from './pages/Login'
import axios from "axios";
import Cookies from "js-cookie"
import DevicesList from "./pages/devices/DevicesList";
import DeviceDetails from './pages/devices/DeviceDetails';
import "./app.css";
import Page404 from './pages/Page404';
import Localisation from './pages/devices/Localisation';
import ConnexionErrorPage from './pages/ConnexionErrorPage';
import UserList from './pages/users/UserList';
import HistoryList from './pages/users/history/HistoryList';
import Unauthorized from './pages/Unauthorized';
import All from './pages/print/All';
import CommuneInDistrict from './pages/print/reference/CommuneInDistrict';
import CsiInCommune from './pages/print/reference/CsiInCommune';
import VillagesInCsi from './pages/print/reference/VillagesInCsi';
import RelaisInVillage from './pages/print/reference/RelaisInVillage';
import PrintCsi from './pages/print/reference/PrintCsi';
import PrintRelais from './pages/print/reference/PrintRelais';
import DevicesHistorique from './pages/devices/AllDevicesHistorique';
import DevicesLostList from './pages/devices/DevicesLostList';
import API_BASE_URL from './hooks/apiBaseUrl.js'

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { io } from 'socket.io-client';


// axios.defaults.baseURL = "http://localhost:3001";
// axios.defaults.baseURL = "http://10.0.0.5:3001"
axios.defaults.baseURL = API_BASE_URL

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.withCredentials = true;


const APICheckStatus = true

axios.interceptors.request.use(function (config) {
  const token = Cookies.get("mhealth_devices_access_token");

  config.headers['Content-Type'] = "application/json"
  config.withCredentials = false
  config.headers['Access-Control-Allow-Credentials'] = false
  config.headers['Access-Control-Allow-Origin'] = "*"
  // console.log("---- Request interceptor ----")
  // console.log(config)

  config.headers.Authorization = token ? `Bearer ${token}` : ""
  return config
})


axios.interceptors.response.use(function (response) {
  response.config.withCredentials = false
  response.headers['Access-Control-Allow-Credentials'] = false
  response.headers['Access-Control-Allow-Origin'] = "*"
  // console.log("---- Response interceptor ----")
  // console.log(response)
  return response;
}, function (error) {
  return Promise.reject(error);
});



function App() {
  const [change_detect, setchange_detect] = useState(null)
  const x = "xxx"
  useEffect(() => {
    const socket = io(API_BASE_URL, {
      // transports: ['websocket', 'polling'],
      path: "/",
    })

    socket.on('connect', (data) => {
      console.log(data)
    });


    // listen for chat events
    socket.on("onDeviceLostRetrieved", (data) => {
      setchange_detect(Math.floor(Math.random() * 10000000) + 1)
      NotificationManager.warning(`Le telephone perdu ID:${data.device_id} vient d'envoyer sa position`, "Mise à jour position", 30000);
    })
  }, [x])


  return (
    <div className="App wrapper">
      <HashRouter>
        {/* <AxiosInterceptorNavigate/> */}
        <Routes>
          <Route path='/' element={<Dashboard change_detect={change_detect} />} />
          <Route path='/device/:device_id/location' element={<Localisation change_detect_={change_detect} />} />
          {/* <Route  path='/device/:device_id/historique' element={<DeviceHistorique change_detect={change_detect}/>}/> */}
          <Route path='/devices/historique' element={<DevicesHistorique change_detect_={change_detect} />} />
          <Route path='/login' element={<Login />} />
          <Route path='/user' element={<UserList change_detect_={change_detect} />} />
          <Route path='/history' element={<HistoryList change_detect_={change_detect} />} />
          <Route path='/device/list' element={<DevicesList change_detect_={change_detect} />} />
          <Route path='/device-lost/list' element={<DevicesLostList change_detect_={change_detect} />} />
          <Route path='/device/:device_id/details' element={<DeviceDetails change_detect={change_detect} />} />

          <Route path='/imprimer-tous' element={<All change_detect_={change_detect} />} />
          <Route path="/imprimer-csi" element={<PrintCsi change_detect_={change_detect} />} />
          <Route path='/imprimer-relais' element={<PrintRelais change_detect_={change_detect} />} />

          <Route path='/imprimer/district/:id/communes' element={<CommuneInDistrict change_detect={change_detect} />} />
          <Route path='/imprimer/commune/:id/csi' element={<CsiInCommune change_detect_={change_detect} />} />
          <Route path='/imprimer/csi/:id/villages' element={<VillagesInCsi change_detect_={change_detect} />} />
          <Route path='/imprimer/village/:id/relais' element={<RelaisInVillage change_detect_={change_detect} />} />



          <Route path='/404' element={<Page404 change_detect={change_detect} />} />
          <Route path='/505' element={<ConnexionErrorPage change_detect={change_detect} />} />
          <Route path='/unauthorized' element={<Unauthorized change_detect={change_detect} />} />

        </Routes>
      </HashRouter>

      <NotificationContainer />
    </div>
  );
}

export default App;
