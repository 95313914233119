import React, { useEffect, useRef, useState } from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import SideBar from '../../components/SideBar';
import District from './District';
import Relais from './Relais';
import Csi from './Csi';
import Village from './Village';
import Commune from './Commune';
import Cookies from 'js-cookie';
import useGet from '../../hooks/useGet';
import "../styles/tablesStyles.css"


const All = ({change_detect}) => {
    
    const { datas:relaisDatas, loading:loadingRelais} = useGet("/api/relais/", "null")
    const { datas:csiDatas, loading:loadingCsi} = useGet("/api/csi/", "null")
    const { datas:villageDatas, loading:loadingVillage} = useGet("/api/village/", "null")
    const { datas:communeDatas, loading:loadingCommune} = useGet("/api/commune/", "null")
    const { datas:districtDatas, loading:loadingDistrict} = useGet("/api/district/", "null")

    const [tableComponent, settableComponent] = useState("")
    
   
    const onSelectButtonChange = (e) => {

        e.preventDefault();
        const tableComponentType = e.target.value 

        if(tableComponentType=="district"){settableComponent(<District districtDatas={districtDatas} localization={localization} loading={loadingDistrict} />)}
        else if(tableComponentType=="commune"){settableComponent(<Commune communeDatas={communeDatas} districtDatas={districtDatas} localization={localization} loading={loadingCommune} />)}
        else if(tableComponentType=="csi"){settableComponent(<Csi csiDatas={csiDatas} communeDatas={communeDatas} localization={localization} loading={loadingCsi} />)}
        else if(tableComponentType=="village"){settableComponent(<Village villageDatas={villageDatas} csiDatas={csiDatas} localization={localization} loading={loadingVillage} />)}
        else if(tableComponentType=="relais"){settableComponent(<Relais relaisDatas={relaisDatas} csiDatas={csiDatas} villageDatas={villageDatas} localization={localization} loading={loadingRelais} />)}
        else{settableComponent(<District districtDatas={districtDatas} localization={localization} loading={loadingDistrict} />)}
    }


    const localization={
        body: {
            emptyDataSourceMessage: "Pas d'enregistreent à afficher",
            addTooltip: 'Ajouter',
            deleteTooltip: 'Supprimer',
            editTooltip: 'Editer',
            filterRow: {
                filterTooltip: 'Filtrer'
            },
            editRow: {
                deleteText: 'Voulez-vous supprimer cette ligne?',
                cancelTooltip: 'Annuler',
                saveTooltip: 'Enregistrer'
            }
        },
        grouping: {
            placeholder: "Tirer l'entête ...",
            groupedBy: 'Grouper par:'
        },
        header: {
            actions: 'Actions'
        },
        pagination: {
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'lignes',
            labelRowsPerPage: 'lignes par page:',
            firstAriaLabel: 'Première page',
            firstTooltip: 'Première page',
            previousAriaLabel: 'Page précédente',
            previousTooltip: 'Page précédente',
            nextAriaLabel: 'Page suivante',
            nextTooltip: 'Page suivante',
            lastAriaLabel: 'Dernière page',
            lastTooltip: 'Dernière page'
        },
        toolbar: {
            addRemoveColumns: 'Ajouter ou supprimer des colonnes',
            nRowsSelected: '{0} ligne(s) sélectionée(s)',
            showColumnsTitle: 'Voir les colonnes',
            showColumnsAriaLabel: 'Voir les colonnes',
            exportTitle: 'Exporter',
            exportAriaLabel: 'Exporter',
            exportName: 'Exporter en CSV',
            searchTooltip: 'Chercher',
            searchPlaceholder: 'Chercher',
            exportPDFName: "Exporter en PDF",
            exportCSVName : "Exporter en CSV"
        }
    }

    
    if(!Cookies.get("mhealth_devices_access_token")){
        return (<Navigate to="/login" />)
    }

    return (
        <div>
                <NavBar change_detect={change_detect} />
                <SideBar/>
                
                <div className="page-wrapper">

                    <div className="page-content">

                            <div>
                                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                                    <div className="breadcrumb-title pe-3 text-light fw-bold">Imprimer tous</div>
                                        <div className="ps-3">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb mb-0 p-0">
                                                    <li className="breadcrumb-item"><NavLink to="/"><i className="bx bx-home-alt"></i></NavLink></li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                            </div>


                            <div className='container-fluid'>

                                <div className='container-fluid'>
                                    <div className="form-group">
                                        <select onChange={onSelectButtonChange} id="districtSelectButton" className="form-select form-select-lg mb-3" aria-label=".form-select-lg example" style={{color:"white"}}>
                                            {/* <option  value="">Selectionner une option</option> */}
                                            <option selected value="district">DISTRICTS</option>
                                            <option value="commune">COMMUNES</option>
                                            <option value="csi">CSI</option>
                                            <option value="village">VILLAGES</option>
                                            <option value="relais">RELAIS</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="card mt-4">
                                    {tableComponent || <District districtDatas={districtDatas} localization={localization} loading={loadingDistrict} /> }
                                </div>

                            </div>

            
            
                    </div>
                </div>
            
        </div>
    );
};

export default All;