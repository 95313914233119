import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import MaterialTable from 'material-table';
import { IconButton } from '@material-ui/core';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';


const Commune = ({communeDatas, districtDatas,  localization, loading}) => {

    const Datas = communeDatas

    const navigate = useNavigate()    

    const columns = [

        { title: "ID", field: "id",filtering: false, filterPlaceholder: "filter", emptyValue: () => <em>-</em> },
        
        // { title: "UID", field: "uid",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em> },
        
        { title: "Code", field: "code",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em> },
        
        { title: "Nom", field: "nom",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em> },
        
        { title: "District", field: "district_id",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em>,
            render: (rowDatas) =>{
                return districtDatas.map(district=>{
                    if(district.id==rowDatas.district_id ){const nom = district.nom; return <span>{nom}</span> }
                })
            }
        },
              

        {
            title : "", align : "right",
            render : (rowData)=>(
                <>                    
                    <IconButton  title="Afficher les CSI de cette commune ?" style={{color : "#fff"}} aria-label="add an alarm">
                        <RemoveRedEye 
                            onClick={(e)=>{ 
                                const url = "/imprimer/commune/" + rowData.id + "/csi"
                                e.preventDefault(); 
                                navigate(url)
                            }} 
                        />
                    </IconButton>
                </>
            )
        }
        
    ]


    return (
        <div>
            <MaterialTable
                title="COMMUNES"
                columns={columns} 
                data={Datas}
                                
                isLoading={loading}

                localization={localization}                
                
                onSelectionChange={(selectedRows) => console.log(selectedRows)}
                options={{
                    sorting: true, search: true,
                    searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
                    filtering: false, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
                    paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
                    exportAllData: true, exportFileName: "Liste des communes", addRowPosition: "first", actionsColumnIndex: -1, selection: false,
                    showSelectAllCheckbox: false, showTextRowsSelected: false,
                    grouping: false, columnsButton: true,
                }}

            />
        </div>
    );
};

export default Commune;