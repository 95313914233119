import React from 'react';
import { Navigate, NavLink, useNavigate, useParams } from 'react-router-dom';
import useGet from '../../../hooks/useGet';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable from 'material-table';
import { IconButton } from '@material-ui/core';
import NavBar from '../../../components/NavBar';
import SideBar from '../../../components/SideBar';
import "../../styles/tablesStyles.css"
import QRCode from 'react-qr-code';
import Cookies from 'js-cookie';


const RelaisInVillage = ({change_detect}) => {

    const navigate = useNavigate()

    const {id} = useParams()

    const { datas:village, status} = useGet("/api/village/" + id + "/", "null")
    const { datas:csi} = useGet("/api/csi/" + village?.csi_id + "/", "null")
    const { datas:commune} = useGet("/api/commune/" + csi?.commune_id + "/", "null")
    const { datas:district} = useGet("/api/district/" + commune?.district_id + "/", "null")
    
    if(status==404){
        navigate("/404")
    }
    if(status==505){
        navigate("/505")
    }


    const { datas:relaisDatas, loading} = useGet("/api/relais/", "null")

    const { datas:villageDatas, loading:loadingVillage} = useGet("/api/village/", "null")

    const datas = relaisDatas.filter((item) => item.village_id == id) 
    

    const columns = [

        

        { title: "ID", field: "id",filtering: false, filterPlaceholder: "filter", emptyValue: () => <em>-</em> },
        
        // { title: "UID", field: "uid",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em> },
        
        { title: "Code", field: "code",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em> },
        
        { title: "Nom", field: "nom",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em> },
        
        { title: "Numero", field: "numero",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em> },
        
        { title: "Prenom", field: "prenom",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em>},
        
        { title: "Village", field: "village_id",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: (rowDatas) => <em>-</em>,
            render: (rowDatas) =>{
                return villageDatas.map(village=>{
                    if(village.id==rowDatas.village_id ){const nom = village.nom; return <span>{nom}</span> }
                })
            }
        },
        
        { title: "Code QR", field: "id",filtering: false, filterPlaceholder: "filter", align:"center", render: (row) => <QRCode size={100} value={row.id}/> },
        
        
    ]


    const exportFileName = "Liste des relais du village de " + village.nom;

    
    if(!Cookies.get("mhealth_devices_access_token")){
        return (<Navigate to="/login" />)
    }


    return (
        <div>
        <NavBar change_detect={change_detect} />
        <SideBar/>
        
        <div className="page-wrapper">

            <div className="page-content">


            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="breadcrumb-title pe-3  text-light h4">Relais</div>
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><NavLink className="text-light" to="/"><i className="bx bx-home-alt"></i></NavLink></li>
                                
                                {district ? <><li className="breadcrumb-item  text-light" aria-current="page">District</li>
                                <li className="breadcrumb-item active text-light fw-bold" aria-current="page">{district.nom }</li>
                                <li className="breadcrumb-item  text-light " aria-current="page">Commune</li>
                                <li className="breadcrumb-item active text-light fw-bold" aria-current="page">{commune.nom || id}</li>
                                <li className="breadcrumb-item active text-light" aria-current="page">csi</li>
                                <li className="breadcrumb-item active text-light fw-bold" aria-current="page">{csi.nom || id}</li></> : ""}

                                <li className="breadcrumb-item active text-light" aria-current="page">Village</li>
                                <li className="breadcrumb-item active text-light fw-bold" aria-current="page">{village.nom || id}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                
                <div className="ms-auto">
                    <NavLink to="/imprimer-tous" ><button type="button" className="btn btn-light">Voir tout</button></NavLink>
                </div>
            </div>


            <div className="mb-3 card">
                <MaterialTable
                    title="Relais"
                    columns={columns} 
                    data={datas}
                
                    isLoading={loading && datas}
                    
                    options={{
                        sorting: true, search: true,
                        searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
                        filtering: false, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
                        paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
                        exportAllData: true, exportFileName: exportFileName, addRowPosition: "first", actionsColumnIndex: -1, selection: false,
                        showSelectAllCheckbox: false, showTextRowsSelected: false,
                        grouping: false, columnsButton: true,
                    }}

                    icons={{ Add: () => <AddIcon /> }}


                    localization={{
                        body: {
                            emptyDataSourceMessage: "Pas d'enregistreent à afficher",
                            addTooltip: 'Ajouter',
                            deleteTooltip: 'Supprimer',
                            editTooltip: 'Editer',
                            filterRow: {
                                filterTooltip: 'Filtrer'
                            },
                            editRow: {
                                deleteText: 'Voulez-vous supprimer cette ligne?',
                                cancelTooltip: 'Annuler',
                                saveTooltip: 'Enregistrer'
                            }
                        },
                        grouping: {
                            placeholder: "Tirer l'entête ...",
                            groupedBy: 'Grouper par:'
                        },
                        header: {
                            actions: 'Actions'
                        },
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'lignes',
                            labelRowsPerPage: 'lignes par page:',
                            firstAriaLabel: 'Première page',
                            firstTooltip: 'Première page',
                            previousAriaLabel: 'Page précédente',
                            previousTooltip: 'Page précédente',
                            nextAriaLabel: 'Page suivante',
                            nextTooltip: 'Page suivante',
                            lastAriaLabel: 'Dernière page',
                            lastTooltip: 'Dernière page'
                        },
                        toolbar: {
                            addRemoveColumns: 'Ajouter ou supprimer des colonnes',
                            nRowsSelected: '{0} ligne(s) sélectionée(s)',
                            showColumnsTitle: 'Voir les colonnes',
                            showColumnsAriaLabel: 'Voir les colonnes',
                            exportTitle: 'Exporter',
                            exportAriaLabel: 'Exporter',
                            searchTooltip: 'Chercher',
                            searchPlaceholder: 'Chercher',
                            exportPDFName: "Exporter en PDF",
                            exportCSVName : "Exporter en CSV"
                        }
                    }}
                />
            </div>
            
        </div>
        </div>
        </div>
    );
};

export default RelaisInVillage;