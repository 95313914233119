import React, { useState } from 'react';
import { Navigate, NavLink, useNavigate } from 'react-router-dom';
import MaterialTable from 'material-table';
import { IconButton } from '@material-ui/core';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';



const District = ({districtDatas, localization, loading}) => {

    const Datas = districtDatas 

    const navigate = useNavigate()    

    const columns = [

        { title: "ID", field: "id",filtering: false, filterPlaceholder: "filter", emptyValue: () => <em>-</em> },
        
        { title: "UID", field: "uid",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em> },
        
        { title: "Code", field: "code",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em> },
        
        { title: "Nom", field: "nom",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em> },
              

        {
            title : "", align : "right",
            render : (rowData)=>(
                <>                    
                    <IconButton  title="Afficher les details de cet utilisateur ?" style={{color : "#fff"}} aria-label="add an alarm">
                        <RemoveRedEye 
                            onClick={(e)=>{ 
                                const url = "/imprimer/district/" + rowData.id + "/communes"
                                e.preventDefault(); 
                                navigate(url)
                            }} 
                        />
                    </IconButton>
                </>
            )
        }
        
    ]
    // 


    return (
            <div>
                <MaterialTable
                    title="DISTRICTS"
                    columns={columns} 
                    data={Datas}

                    localization={localization}
                                
                    isLoading={loading}
                    
                    
                    onSelectionChange={(selectedRows) => console.log(selectedRows)}
                    options={{
                        sorting: true, search: true,
                        searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
                        filtering: false, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
                        paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
                        exportAllData: true, exportFileName: "Liste des districts", addRowPosition: "first", actionsColumnIndex: -1, selection: false,
                        showSelectAllCheckbox: false, showTextRowsSelected: false,
                        grouping: false, columnsButton: true,
                    }}

                />
            </div>
    );
};

export default District;