import React, { Component } from 'react';
import CanvasJSReact from '../assets/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
 


const FlotteStateChart = ({csi, relais, csi_relais, total}) => {

    const setPercentage = (number, total)=>{
        return ((number * 100) / total).toFixed(2)
    }

    const options = {
        exportEnabled: true,
        animationEnabled: true,
        // title: {
        // 	text: "Website Traffic Sources"
        // },
        data: [{
            type: "pie",
            startAngle: 75,
            toolTipContent: "<b>{label}</b>: {y}%",
            showInLegend: "true",
            legendText: "{label}",
            indexLabelFontSize: 16,
            indexLabel: "{label} - {y}%",
            dataPoints: [
                { y: setPercentage(csi, total), label: "Appareils CSI" },
                { y: setPercentage(relais, total), label: "Appareils RELAIS" },
                { y: setPercentage(csi_relais, total), label: "Appareils CSI & RELAIS" },
                // { y: setPercentage(csi, total), label: "Referral" },
                // { y: setPercentage(csi, total), label: "Social" }
            ]
        }]
    }
    
    return (
        <div>
            <h3 className='pb-2'>Composition de la flotte</h3>
            <CanvasJSChart options = {csi && relais && csi_relais && total ? options : ""} 
                /* onRef={ref => this.chart = ref} */
            />
            {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
        </div> 
    );
};

export default FlotteStateChart;