import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { Navigate, NavLink, useNavigate, useParams } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import SideBar from '../../components/SideBar';
import { Map as PigeonMap, Marker as PigeonMarker, ZoomControl as PigeonZoomControl } from "pigeon-maps"
import axios from 'axios';
import dateFormat from "dateformat"
import useGet from '../../hooks/useGet';
import DatePicker from 'react-date-picker';
import { Icon } from '@iconify/react';
import MaterialTable from 'material-table';
import AddIcon from '@material-ui/icons/Add';
import { IconButton } from '@material-ui/core';
import TabletAndroid from '@material-ui/icons/TabletAndroid';




const DevicesHistorique = ({change_detect}) => {

    const navigate = useNavigate()

    const [selectedYear, setselectedYear] = useState("")
    const [selectedMonth, setselectedMonth] = useState("")
    const [selectedDay, setselectedDay] = useState("")

    const [loading, setloading] = useState(true)

    const columns = [
        { title: "Device_id", field: "device_id", sorting: true, filtering: false},

        { title: "Action", field: "action", sorting: true, filtering: true, 
            lookup: { "Enrolement": "Enrolement", "Vaccination": "Vaccination", "Commande intrants": "Commande intrants" }
        },

        { title: "Date", field: "date", sorting: true, filtering: false, render :(rowData)=><em> {dateFormat(rowData.date, "mm/dd/yyyy à H:MM")} </em>},

        {
            title : "Actions",
            render : (rowData)=>(
                <>                    
                    <IconButton  title="Afficher l'historique de cet appareil ?" color="light" aria-label="add an alarm">
                        <TabletAndroid style={{color : "#fff"}} onClick={(e)=>{ e.preventDefault(); navigate("/device/" + rowData.device_id + "/details") }}  />
                    </IconButton>
                </>
            )
        }
    ]

    const historiqueDatas = [
        {
            "device_id": "05928250BR000619",
            "action": "Enrolement",
            "date": new Date()
        },
        {
            "device_id": "1090373021030211",
            "action": "Vaccination",
            "date": Date()
        },
        {
            "device_id": "1090373021030030",
            "action": "Commande intrants",
            "date": Date()
        },
    ]

    const [filteredHistoriqueData, setfilteredHistorique] = useState([...historiqueDatas])

    useEffect(() => {
        setfilteredHistorique([...historiqueDatas])
    }, [historiqueDatas])


// Filtrer les historiques en fonction de l'annee
    const filterHistoriqueByYear = (newYear)=>{
        setloading(true)
        if(!newYear) {            
            setselectedYear("")
            setfilteredHistorique([...historiqueDatas])
            setloading(false) 
        }
        else{
            const year = (newYear?.toString().split(' '))[3]
            console.log(year)
            const toFilt = historiqueDatas?.filter(h=>(h?.date)?.includes(" " + year))
            setfilteredHistorique(toFilt) 
        }    
        setloading(false) 
    }

   


    if(!Cookies.get("mhealth_devices_access_token")){
        return (<Navigate to="/login" />)
    }
    
    return (
            <div>
            <NavBar change_detect={change_detect} />
            <SideBar/>
                

            <div className="page-wrapper">

            <div className="page-content">

                <div>
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="breadcrumb-title text-light h4 pe-3">Historique</div>
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><NavLink className="text-light" to="/"><i className="bx bx-home-alt"></i></NavLink>
                                </li>
                                <li className="breadcrumb-item active text-light text-decoration-none fw-bold" aria-current="page"><NavLink className="text-light" to="/device/list">Liste</NavLink></li>
                                <li className="breadcrumb-item active text-light" aria-current="page">Tous les appareils</li>
                                <li className="breadcrumb-item active text-light" aria-current="page">historique</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                </div> 
                <div>


                    {/* <div className="card p2">
                        <span className="m-2 text-light ms-3">ok</span>
                    </div> */}
                    <div className="card">
                            <div className="card-body">


                                <div className="my-3 row mx-1">
                                    <div className='col-3'><h4>Trie :</h4></div>
                                    <div className='col-3'>
                                        <span className='h6'>Année : </span>
                                        <DatePicker 
                                            onChange={(newYear)=>{
                                                setselectedYear(newYear)
                                                filterHistoriqueByYear(newYear)
                                            }}

                                            format = "y"
                                            value={selectedYear}
                                            disabled = {filteredHistoriqueData ? false : true}
                                            maxDetail = "decade"
                                            minDetail = "decade"
                                            showLeadingZeros={false}
                                            calendarIcon = {<Icon icon="bi:calendar" width="20" height="20" hFlip={true} vFlip={true} className="text-light" />}
                                            clearIcon= {<Icon icon="ic:round-clear" width="20" height="20" hFlip={true} vFlip={true} className="text-light"/>}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <span className='h6'>Mois : </span>
                                        <DatePicker 
                                            onChange={(newMonth)=>{
                                                
                                            }}
                                            format = "MM"
                                            showLeadingZeros={false}
                                            nativeInputAriaLabel ="Mois"
                                            value={""}
                                            maxDetail = "year"
                                            minDetail = "year"
                                            disabled = {selectedYear!="" ? false : true}
                                            calendarIcon = {<Icon icon="bi:calendar" width="20" height="20" hFlip={true} vFlip={true} className="text-light" />}
                                            clearIcon= {<Icon icon="ic:round-clear" width="20" height="20" hFlip={true} vFlip={true} className="text-light"/>}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <span className='h6'>Jour : </span>
                                        <DatePicker 
                                            onChange={(newDay)=>{
                                                
                                            }}
                                            format = "dd"
                                            value={""}
                                            showLeadingZeros={false}
                                            maxDetail = "month"
                                            minDetail = "month"
                                            disabled =  {selectedYear!="" && selectedMonth!="" ? false : true}
                                            calendarIcon = {<Icon icon="bi:calendar" width="20" height="20" hFlip={true} vFlip={true} className="text-light" />}
                                            clearIcon= {<Icon icon="ic:round-clear" width="20" height="20" hFlip={true} vFlip={true} className="text-light"/>}
                                        />
                                    </div>
                                </div>



                                

                                <MaterialTable
                                    title="Appareils"
                                    columns={columns} 
                                    data={filteredHistoriqueData} 
                                    
                                    isLoading={false}

                                    onSelectionChange={(selectedRows) => console.log(selectedRows)}
                                    options={{
                                        sorting: true, search: true,
                                        searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
                                        filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
                                        paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
                                        exportAllData: true, exportFileName: "mHealthDeviceDatas", addRowPosition: "first", actionsColumnIndex: -1, selection: false,
                                        showSelectAllCheckbox: false, showTextRowsSelected: false,
                                        grouping: false, columnsButton: true,
                                    }}

                                    icons={{ Add: () => <AddIcon /> }}

                                    localization={{
                                        body: {
                                            emptyDataSourceMessage: "Pas d'historique à afficher",
                                            addTooltip: 'Ajouter',
                                            deleteTooltip: 'Supprimer',
                                            editTooltip: 'Editer',
                                            filterRow: {
                                                filterTooltip: 'Filtrer'
                                            },
                                            editRow: {
                                                deleteText: 'Voulez-vous supprimer cette ligne ?',
                                                cancelTooltip: 'Annuler',
                                                saveTooltip: 'Enregistrer'
                                            }
                                        },
                                        grouping: {
                                            placeholder: "Tirer l'entête ...",
                                            groupedBy: 'Grouper par:'
                                        },
                                        header: {
                                            actions: 'Actions'
                                        },
                                        pagination: {
                                            labelDisplayedRows: '{from}-{to} de {count}',
                                            labelRowsSelect: 'lignes',
                                            labelRowsPerPage: 'lignes par page:',
                                            firstAriaLabel: 'Première page',
                                            firstTooltip: 'Première page',
                                            previousAriaLabel: 'Page précédente',
                                            previousTooltip: 'Page précédente',
                                            nextAriaLabel: 'Page suivante',
                                            nextTooltip: 'Page suivante',
                                            lastAriaLabel: 'Dernière page',
                                            lastTooltip: 'Dernière page'
                                        },
                                        toolbar: {
                                            addRemoveColumns: 'Ajouter ou supprimer des colonnes',
                                            nRowsSelected: '{0} ligne(s) sélectionée(s)',
                                            showColumnsTitle: 'Voir les colonnes',
                                            showColumnsAriaLabel: 'Voir les colonnes',
                                            exportTitle: 'Exporter',
                                            exportAriaLabel: 'Exporter',
                                            exportName: 'Exporter en CSV',
                                            searchTooltip: 'Chercher',
                                            searchPlaceholder: 'Chercher',
                                            exportPDFName: "Exporter en PDF",
                                            exportCSVName : "Exporter en CSV"
                                        }
                                    }}
                                />





                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default DevicesHistorique;