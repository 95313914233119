import React from 'react';

const Footer = () => {
    return (
        <div className=""  >
            <footer className="page-footer">
                <p className="mb-0">Copyright Ticanalyse 2022. All right reserved.</p>
            </footer>
        </div>
    );
};

export default Footer;