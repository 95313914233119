import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import NavBar from '../../../components/NavBar';
import SideBar from '../../../components/SideBar';
import useGet from '../../../hooks/useGet';

const PrintRelais = ({change_detect}) => {

    const { datas:datas, loading:loadingData} = useGet("/api/relais/print", "null")
    console.log(datas)
    
    const [chooseDistrict, setchooseDistrict] = useState("")
    const [chooseRelais, setchooseRelais] = useState("")

    const onDistrictSelectButtonChange = () => {}
    const onCsiSelectButtonChange = () => {}

    return (
        <div>
                <NavBar change_detect={change_detect} />
                <SideBar/>
                
                <div className="page-wrapper">

                    <div className="page-content">

                            <div>
                                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                                    <div className="breadcrumb-title pe-3 text-light fw-bold">Impression CSI</div>
                                        <div className="ps-3">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb mb-0 p-0">
                                                    <li className="breadcrumb-item"><NavLink to="/"><i className="bx bx-home-alt"></i></NavLink></li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                            </div>


                            <div className='container-fluid'>

                                <div className='container-fluid card p-2'>
                                    <div className='row'>
                                        <div className="form-group col-6">
                                            <fieldset> <legend>Choix district</legend>
                                                    <select onChange={onDistrictSelectButtonChange} id="districtSelectButton" className="form-select form-select-lg" aria-label=".form-select-lg example" style={{color:"white"}}>
                                                        <option selected value="">...</option>
                                                        {
                                                            datas?.map(data=>{
                                                                return(
                                                                    <option value={data?.nom_district}>{data?.code_district}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                            </fieldset>
                                        </div>
                                        
                                        <div className="form-group col-6">
                                            <fieldset> <legend>Choix district</legend>
                                                    <select onChange={onDistrictSelectButtonChange} id="districtSelectButton" className="form-select form-select-lg" aria-label=".form-select-lg example" style={{color:"white"}}>
                                                        <option selected value="">...</option>
                                                        {
                                                            datas?.map(district=>{
                                                                (district?.communes)?.map(commune=>{
                                                                    (commune?.csi)?.map(csi=>{
                                                                        return(
                                                                            <option value={csi?.code_csi}>{csi?.nom_csi}</option>
                                                                        )
                                                                    })
                                                                })
                                                            })
                                                        }
                                                    </select>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>

                                <div className="card mt-4">

                                </div>

                            </div>

            
            
                    </div>
                </div>
        </div>
    );
};

export default PrintRelais;