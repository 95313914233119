import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import CanvasJSReact from '../assets/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


const EnrolDeviceCharts = ({datas}) => {

	const [deviceData, setdeviceData] = useState(datas || [])

	// axios.get("api/device/")
	// 		.then((res) => {
	// 			setdeviceData(res.data.data)
	// 		})
	
			useEffect(() => {
				setdeviceData(datas)
			}, [])
			

	const getMonthDeviceLenght = (month, year)=>{
		const year_devices = deviceData?.filter(device => (device?.date_creation)?.includes(year + "-" +month))
		return Object.keys(year_devices).length || 0
	}

	// console.log(getMonthDeviceLenght(10, 2022))

	const options = {
		exportEnabled: true,
		animationEnabled: true,
		// theme: "dark1",
		axisX: {
			valueFormatString: "MMM YY",
		},
		axisY: {
			title: "Téléphones enrolés",
			includeZero: true,
			minimum : 0,
			maximum : 1000
		},
		data: [{
			yValueFormatString: "####",
			xValueFormatString: "MMMM",
			type: "area",
			dataPoints: [
				// { x: new Date(2022, 1), y: getMonthDeviceLenght("01", 2022) },
				// { x: new Date(2022, 2), y: getMonthDeviceLenght("02", 2022) },
				// { x: new Date(2022, 3), y: getMonthDeviceLenght("03", 2022) },
				// { x: new Date(2022, 4), y: getMonthDeviceLenght("04", 2022) },
				// { x: new Date(2022, 5), y: getMonthDeviceLenght("05", 2022) },
				// { x: new Date(2022, 6), y: getMonthDeviceLenght("06", 2022) },
				// { x: new Date(2022, 7), y: getMonthDeviceLenght("07", 2022) },

				{ x: new Date(2022, 8), y: getMonthDeviceLenght("08", 2022) },
				{ x: new Date(2022, 9), y: getMonthDeviceLenght("09", 2022) },
				{ x: new Date(2022, 10), y: getMonthDeviceLenght("10", 2022) },
				{ x: new Date(2022, 11), y: getMonthDeviceLenght("11", 2022) },
				{ x: new Date(2022, 12), y: getMonthDeviceLenght("12", 2022) },
				{ x: new Date(2023, 1), y: getMonthDeviceLenght("01", 2023) },
				{ x: new Date(2023, 2), y: getMonthDeviceLenght("02", 2023) },
				{ x: new Date(2023, 3), y: getMonthDeviceLenght("03", 2023) },
				{ x: new Date(2023, 4), y: getMonthDeviceLenght("04", 2023) }
			]
		}],
		navigator: {
			slider: {
				minimum: new Date("2022-08-01"),
				maximum: new Date("2022-12-31")
			}
		}
	}
	
	return (
			deviceData != [] ?
			<div>
				<h3 className='pb-2'>Evolution de la flotte</h3>
				<CanvasJSChart options = {deviceData ? options : ""} 
				/>
			</div> :

			<div className="container text-center d-flex justify-content-center jumbotron d-flex align-items-center ">
				<center className='row '>
					<Oval
					height={100} 
					width={100}
					color="#0c675e"
					wrapperStyle={{}}
					wrapperClass=""
					visible={true}
					ariaLabel='oval-loading'
					secondaryColor="#fff"
					strokeWidth={2}
					strokeWidthSecondary={2}

					/>
				</center>
			</div>
	);
};

export default EnrolDeviceCharts;
