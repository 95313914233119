import axios from 'axios';
import Cookies from 'js-cookie';
import MaterialTable from 'material-table';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Navigate, NavLink, useNavigate } from 'react-router-dom';
import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';
import SideBar from '../../../components/SideBar';
import useGet from '../../../hooks/useGet';
import "../../styles/tablesStyles.css"
import {useReactToPrint} from "react-to-print"

const HistoryList = ({change_detect_}) => {

    // useEffect(() => {
    //     var table = document.getElementsByClassName('MuiTable-root')
    //     setelementToPrint(table)
    // }, [])
    
    // const [elementToPrint, setelementToPrint] = useState(document.getElementById("print-table"))

    // const printTable = useReactToPrint({
    //     content : ()=> elementToPrint,
    //     documentTitle : "Historique admin",
    // })    

    const navigate = useNavigate()

    const [change_detect, setchange_detect] = useState(Math.floor(Math.random() * 10000000) + 1)

    const  { datas:historyDatas, count, error, loading } = useGet("/api/historique/", change_detect)  
    console.log(historyDatas)

    

    const columns = [

        { title: "ID", field: "id",filtering: false, filterPlaceholder: "filter", emptyValue: () => <em>-</em> },
        
        { title: "Nom", field: "user",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em> },
        
        { title: "Action", field: "task",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em> },
        
        { title: "Date", field: "date",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em> },
        
    ]

    

    
    if(!Cookies.get("mhealth_devices_access_token")){
        return (<Navigate to="/login" />)
    }

    return (
            <>
                <NavBar/>
                <NavBar change_detect={change_detect_} />
                    
                <div className="page-wrapper">

                    <div className="page-content">

                            <div>
                                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                                    <div className="breadcrumb-title pe-3 text-light h4">Historique</div>
                                        <div className="ps-3">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb mb-0 p-0">
                                                    <li className="breadcrumb-item"><NavLink className="text-light" to="/"><i className="bx bx-home-alt"></i></NavLink></li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                            </div>

                            
                            {/* <button name="" id="" 
                                onClick={(e)=>{
                                    e.preventDefault();
                                    printTable()
                                }}  
                                 className="btn btn-primary">Print</button> */}

                            <div>

                        
                                <div id='element-to-print' className="mb-3 card">
                                    <MaterialTable
                                        title="Historique"
                                        columns={columns} 
                                        data={historyDatas}
                                
                                        isLoading={loading}
                                        
                                        
                                        onSelectionChange={(selectedRows) => console.log(selectedRows)}
                                        options={{
                                            sorting: true, search: true,
                                            searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
                                            filtering: false, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
                                            paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
                                            exportAllData: true, exportFileName: "mHealthDeviceDatas", addRowPosition: "first", actionsColumnIndex: -1, selection: false,
                                            showSelectAllCheckbox: false, showTextRowsSelected: false,
                                            grouping: false, columnsButton: true,
                                        }}


                                        localization={{
                                            body: {
                                                emptyDataSourceMessage: "Pas d'historique à afficher",
                                                addTooltip: 'Ajouter',
                                                deleteTooltip: 'Supprimer',
                                                editTooltip: 'Editer',
                                                filterRow: {
                                                    filterTooltip: 'Filtrer'
                                                },
                                                editRow: {
                                                    deleteText: 'Voulez-vous supprimer cette ligne?',
                                                    cancelTooltip: 'Annuler',
                                                    saveTooltip: 'Enregistrer'
                                                }
                                            },
                                            grouping: {
                                                placeholder: "Tirer l'entête ...",
                                                groupedBy: 'Grouper par:'
                                            },
                                            header: {
                                                actions: 'Actions'
                                            },
                                            pagination: {
                                                labelDisplayedRows: '{from}-{to} de {count}',
                                                labelRowsSelect: 'lignes',
                                                labelRowsPerPage: 'lignes par page:',
                                                firstAriaLabel: 'Première page',
                                                firstTooltip: 'Première page',
                                                previousAriaLabel: 'Page précédente',
                                                previousTooltip: 'Page précédente',
                                                nextAriaLabel: 'Page suivante',
                                                nextTooltip: 'Page suivante',
                                                lastAriaLabel: 'Dernière page',
                                                lastTooltip: 'Dernière page'
                                            },
                                            toolbar: {
                                                addRemoveColumns: 'Ajouter ou supprimer des colonnes',
                                                nRowsSelected: '{0} ligne(s) sélectionée(s)',
                                                showColumnsTitle: 'Voir les colonnes',
                                                showColumnsAriaLabel: 'Voir les colonnes',
                                                exportTitle: 'Exporter',
                                                exportAriaLabel: 'Exporter',
                                                exportName: 'Exporter en CSV',
                                                searchTooltip: 'Chercher',
                                                searchPlaceholder: 'Chercher'
                                            }
                                        }}

                                    />
                                </div>





                            </div>
                            
                    </div>
                </div>
                {/* <Footer className="fixed-bottom"  /> */}
            </>
    );
};

export default HistoryList;