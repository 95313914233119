
import axios from "axios"
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const  useGet = (url, change_detect) => {

    const navigate = useNavigate()

    const [datas, setDatas] = useState([]);
    const [error, setError] = useState('ok');
    const [loading, setloading] = useState(true);
    const [status, setstatus] = useState(200);
    const [count, setcount] = useState("")

    const recup = async function (){
        await axios
        .get(url)
        .then((res) => {
            if(res.status===200){
                setstatus(200)
                setDatas(res.data.data);
                setloading(false)
                setcount(Object.keys(res.data?.data).length);
            }
        })
        .catch((err) => {
            setError(err);
            // console.log("------------error-------------")
            // console.log(err.response.data.message)
            if(err.response?.data?.message==="JWT signature Expired"){
                Cookies.remove('mhealth_devices_user_infos')
                Cookies.remove('mhealth_devices_access_token')
                navigate("/login")
            }
            setloading(false)
            setstatus(err.response?.data?.code)
        })
        .finally(() => {
            setloading(false);
        });
    }

      useEffect(() => { 
        recup()
      }, [url, change_detect])


    return { datas, count, error, loading, status };

}
 
export default useGet;