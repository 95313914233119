import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import GetIfNewDeviceHashBeenAded from '../hooks/GetIfNewDeviceHashBeenAded';

const SideBar = () => {

    const loc = (useLocation()).pathname

    // GetIfNewDeviceHashBeenAded()

    return (
        <div>
            <div className="sidebar-wrapper" data-simplebar="true">
                <div className="sidebar-header">
                    <div>
                        <img src="https://i.ibb.co/SRQLLCF/logom-Health-White.png" className="logo-icon" alt="logo icon"/>
                    </div>
                    <div>
                        <h6 className="ms-2" >mHealth Devices Manager</h6>
                    </div>
                    <div className="toggle-icon ms-auto">
                        <i className='bx bx-arrow-to-left'></i>
                    </div>
                </div>
                <ul className="metismenu" id="menu">
      
                    <li className="mt-2">
                        <NavLink className={loc==="/" ? "mm-active" : "" } to="/">
                            <div className="parent-icon"><i className="bx bx-home-circle"></i>
                            </div>
                            <div className="menu-title">Dashboard</div>
                        </NavLink>
                    </li>

                    {/* <hr/> */}
                    
                    <li className="mt-2">
                        <NavLink className={loc==="/device/list" ? "mm-active" : "" }   to="/device/list" >
                            <div className="parent-icon"><iconify-icon icon="akar-icons:mobile-device"></iconify-icon>
                            </div>
                            <div className="menu-title">Appareils</div>
                        </NavLink>
                    </li>
                    {/* <hr/> */}
                    
      
                    {/* <li className="mt-2">
                        <NavLink className={loc==="/devices/historique" ? "mm-active" : "" } to="/devices/historique">
                            <div className="parent-icon"><iconify-icon icon="fluent-mdl2:full-history"></iconify-icon>
                            </div>
                            <div className="menu-title">Historique</div>
                        </NavLink>
                    </li> */}

                    

                    <li className={loc==="/imprimer-tous" || loc=="/imprimer-district" || loc=="/imprimer-csi"  ? "mm-active" : "" }>
                        <div className="accordion accordion-flush" id="ImpressionaccordionFlushExample">
                            <div className="accordion-item">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#Impression-flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    <div className="parent-icon"><iconify-icon className="me-2" icon="bytesize:print"></iconify-icon></div>
                                    <div className="menu-title ms-3">Impression</div>
                                </button>
                                <div id="Impression-flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#ImpressionaccordionFlushExample">
                                    <div className="accordion-body bg-transparent">
                                        <ul>
                                            <li> <NavLink className={loc==="/imprimer-tous" ? "mm-active" : "" } to="/imprimer-tous" ><div className="ms-2" >Generale</div></NavLink></li>
                                            {/* <li> <NavLink className=""  to="#"><div className="ms-2" >District</div></NavLink></li> */}
                                            {/* <li> <NavLink className={loc==="/imprimer-district" ? "mm-active" : "" }  to="/imprimer-district"><div className="ms-2" >District</div></NavLink></li> */}
                                            {/* <li> <NavLink className=""  to="#"><div className="ms-2" >CSI</div></NavLink></li> */}
                                            {/* <li> <NavLink className={loc==="/imprimer-csi" ? "mm-active" : "" }  to="/imprimer-csi"><div className="ms-2" >CSI</div></NavLink></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>


                    <li className={loc==="/user" || loc==="/history" ? "mm-active mt-2" : "mt-2" }>
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            <div className="accordion-item">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#Utilisateurs-flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    <div className="parent-icon"><iconify-icon icon="ic:twotone-admin-panel-settings"></iconify-icon></div>
                                    <div className="menu-title ms-3">Admin</div>
                                </button>
                                <div id="Utilisateurs-flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body bg-transparent">
                                        <ul>
                                            <li> <NavLink className={loc==="/user" ? "mm-active" : "" } to="/user" ><iconify-icon icon="bxs:user"></iconify-icon><div className="ms-2" >Utilisateurs</div></NavLink>
                                            </li>
                                            <li> <NavLink className={loc==="/history" ? "mm-active" : "" }  to="/history"><iconify-icon icon="bi:clock-history" ></iconify-icon><div className="ms-2" >Historique</div></NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default SideBar;