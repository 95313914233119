import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import SideBar from '../../components/SideBar';
import useGet from '../../hooks/useGet';
import ReactToPrint from 'react-to-print';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import MaterialTable from 'material-table';
import axios from 'axios';
import QRCode from 'react-qr-code';


const Relais = ({relaisDatas, csiDatas, villageDatas, localization, loading}) => {

    
    const Datas = relaisDatas
    const navigate = useNavigate()    

    const columns = [

        { title: "ID", field: "id",filtering: false, filterPlaceholder: "filter", emptyValue: () => <em>-</em> },
        
        // { title: "UID", field: "uid",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em> },
        
        { title: "Code", field: "code",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em> },
        
        { title: "Nom", field: "nom",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em> },
        
        { title: "Numero", field: "numero",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em> },
        
        { title: "Prenom", field: "prenom",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: () => <em>-</em>},
        
        { title: "Village", field: "village_id",filtering: false, filterPlaceholder: "filter", align:"center", emptyValue: (rowDatas) => <em>-</em>,
            render: (rowDatas) =>{
                return villageDatas.map(village=>{
                    if(village.id==rowDatas.village_id ){const nom = village.nom; return <span>{nom}</span> }
                })
            }
        },
        
        { title: "Code QR", field: "id",filtering: false, filterPlaceholder: "filter", align:"center", render: (row) => <QRCode size={100} value={row.id}/> },
        
    ]


    return (
        <div>
            <MaterialTable
                title="RELAIS"
                columns={columns} 
                data={Datas}
                                
                isLoading={loading}

                localization={localization}
                
                
                onSelectionChange={(selectedRows) => console.log(selectedRows)}
                options={{
                    sorting: true, search: true,
                    searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
                    filtering: false, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
                    paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
                    exportAllData: true, exportFileName: "Liste des relais", addRowPosition: "first", actionsColumnIndex: -1, selection: false,
                    showSelectAllCheckbox: false, showTextRowsSelected: false,
                    grouping: false, columnsButton: true,
                }}

            />
        </div>
    );
};

export default Relais;